<script setup lang="ts">
import type { ZodSchema } from 'zod'
import { useUnifiiField } from '@/composables/inputs'
import { toRef, ref } from 'vue'
import { faClock } from '@fortawesome/pro-light-svg-icons'
import { withoutKeys } from '@/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const $el = ref<HTMLInputElement>()

const emit = defineEmits<{
  'update:value': [string|undefined]
}>()

const props = withDefaults(
  defineProps<
    Partial<{
      name: string
      value: string
      rules: ZodSchema
      inline: boolean,
      required: boolean
      min: string
      max: string
      disabled: boolean
    }>
  >(),
  { name: '', value: undefined }
)

const {
  errorMessage,
  value: currentValue,
  handleBlur,
  handleChange,
  handleInput
} = useUnifiiField({
  name: toRef(props, 'name'),
  rules: toRef(props, 'rules'),
  initialValue: toRef(props, 'value'),
  emitter: (value: string|undefined) => emit('update:value', value)
})

function showPicker() {
  if (props.disabled || !$el.value) {
    return
  }

  $el.value.focus()
  $el.value.showPicker()
}

</script>

<template>
  <div :class="{ 'd-inline-block': inline, 'd-block': !inline }">
    <div class="input-group position-relative"
         :class="[
           $style['input-time'],
           $attrs.class,
           {
            'has-validation': errorMessage,
            [$style.required]: required
           }
         ]">
      <input v-bind="withoutKeys($attrs, ['class'])"
             ref="$el"
             :name="name"
             :disabled="disabled"
             :value="currentValue"
             type="time"
             class="form-control"
             :min="min"
             :max="max"
             :class="{ 'is-invalid': errorMessage }"
             v-tooltip="errorMessage ? { text: errorMessage, variant: 'error' } : undefined"
             @change="handleChange"
             @input="handleInput"
             @blur="handleBlur" />
      <span class="input-group-text" @click="showPicker">
        <FontAwesomeIcon :icon="faClock" style="width: 15px" />
      </span>
    </div>
  </div>
</template>

<style module lang="scss">
@use "@/assets/mixins/inputs";
@use "@/assets/styles/mixins-inputs";
@import "@/assets/variables";

.input-time {
  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  @include mixins-inputs.fix-error-tooltips-for-inputs;

  // Apply styling for prefixes/suffixes.
  @include inputs.apply-prefix-and-suffix-styles;
}

.required {
  @include inputs.apply-required-indicator-styles;
}
</style>
