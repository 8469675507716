<template>
  <div data-color-scheme="dark" :class="$style['page-header']" class="d-flex align-items-center">
    <div class="container-sm d-flex justify-content-between ps-0 pe-0">
      <slot />
    </div>
  </div>
</template>

<style module lang="scss">
.page-header {
  min-height: 80px;
  background: #35515F;
  color: #fff;
}
</style>
