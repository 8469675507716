<script async setup lang="ts">
import useOrganisationList from '@/composables/organisations/organisation-list'
import ContentLoader from '@/components/content-loader.vue'
import { ref, watch } from "vue";
import { faShuffle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import ModalBase from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import InputSelect from '@/components/input-select.vue'
import InputLabel from '@/components/input-label.vue'
import useUserList from '@/composables/users/user-list'
import GridContainer from '@/components/grid-container.vue'
import GridItem from '@/components/grid-item.vue'
import ModalFooter from "@/components/modal/modal-footer.vue";
import ButtonSecondary from "@/components/button-secondary.vue";
import ButtonPrimary from "@/components/button-primary.vue";
import useProjectTransfers from "@/composables/projects/project-transfers";
import type { Projects } from '@/types'
import useNotifications from "@/composables/notifications";

const $emit = defineEmits<{ close: [] }>()
const $props = defineProps<{ type: Projects.TransferType, projectId: number }>()

const selectedOrganisationId = ref<number>()
const selectedUserId = ref<string>()

const { addSuccess, addError } = useNotifications()

const {
  data: organisations,
  isFetching: organisationsAreLoading,
} = useOrganisationList()

const {
  data: users,
  isFetching: usersAreLoading,
} = useUserList(selectedOrganisationId)

const {
  createTransfer: {
    mutateAsync: transferProject,
    isPending: isTransferring,
    error: transferError
  }
} = useProjectTransfers({
  type: $props.type,
  projectId: $props.projectId,
})

watch(
  selectedOrganisationId,
  () => selectedUserId.value = undefined
)

watch(
  organisations,
  (organisations) => {
    if (organisations === undefined) {
      return
    }

    if (organisations.length === 1) {
      selectedOrganisationId.value = organisations[0].id
    }
  },
  { immediate: true }
)

async function initiateTransfer() {
  const currentUser = users.value!.find(x => x.id === selectedUserId.value)!

  try {
    await transferProject({ userId: selectedUserId.value! })

    addSuccess({
      title: 'Project transferred successfully.',
      message: `${currentUser.name} will receive a notification to complete the project's transfer.`
    })

  } catch (e: unknown) {
    addError({
      title: 'Unable to transfer project',
      message: `Something went wrong trying to transfer project ownership to ${currentUser.name}. Please try again.`
    })
  }

  $emit('close')
}

</script>

<template>
  <ModalBase :width="355" @close="$emit('close')" #default="{ close }">
    <ModalTitle>{{ type === 'owner' ? 'Transfer ownership' : 'Assign project' }}</ModalTitle>
    <ModalContent>
      <p v-if="transferError" style="color: red">Unable to initiate transfer.</p>
      <GridContainer variant="inputs">

        <GridItem span v-if="organisationsAreLoading">
          <ContentLoader loading />
        </GridItem>
        <template v-else>
          <GridItem>
            <InputLabel class="card-input-title">Organisation</InputLabel>
          </GridItem>
          <GridItem>
            <InputSelect v-if="organisations && organisations.length > 1" name="organisationId"
              :items="organisations || []" item-text="name" item-value="id" v-model:selected="selectedOrganisationId" />
            <span v-else-if="organisations?.length === 1">{{ organisations[0].name }}</span>
          </GridItem>
        </template>

        <template v-if="selectedOrganisationId">
          <GridItem span v-if="usersAreLoading">
            <ContentLoader loading />
          </GridItem>
          <template v-else>
            <GridItem>
              <InputLabel class="card-input-title">Users</InputLabel>
            </GridItem>
            <GridItem>
              <InputSelect name="userId" :items="users || []" item-text="name" item-value="id"
                v-model:selected="selectedUserId" />
            </GridItem>
          </template>
        </template>
      </GridContainer>
    </ModalContent>
    <ModalFooter class="text-end">
      <ButtonSecondary @click="close()" :disabled="isTransferring" :icon="faTimes">Cancel</ButtonSecondary>
      <ButtonPrimary class="ms-2" @click="initiateTransfer" :disabled="!selectedOrganisationId || !selectedUserId"
        :preset="isTransferring ? 'loading' : undefined" :icon="faShuffle">Transfer</ButtonPrimary>
    </ModalFooter>
  </ModalBase>
</template>

<style module lang="scss"></style>