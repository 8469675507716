import { type MaybeRefOrGetter, toValue } from "vue";
import { useQuery } from '@tanstack/vue-query'
import getPaginatedProjectList from '@/features/projects/get-paginated-project-list'
import type { PaginationParameters } from "@/composables/query-parameters";
import { createLogger } from "@/helpers/logging";

export default function usePaginatedProjectList(params: PaginationParameters & { filter: MaybeRefOrGetter<string|undefined> }) {
  return useQuery({
    queryKey: ['projects:list', params],
    meta: {
      logger: createLogger(`composables/projects/usePaginatedProjectList`)
    },
    placeholderData: (previousData) => previousData,
    queryFn: () =>
      getPaginatedProjectList({
        page: toValue(params.page),
        pageSize: toValue(params.pageSize),
        sort: toValue(params.sorting),
        filter: toValue(params.filter)
      })
  })
}
