<script setup lang="ts">
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { RouterLink } from 'vue-router'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

defineOptions({ inheritAttrs: false })

const props = withDefaults(
  defineProps<{
    href?: string | RouteLocationRaw,
    icon?: IconDefinition,
    disabled?: boolean,
  }>(),
  {
    disabled: false
  }
)

const isAbsoluteLink = computed(
  () => typeof props.href === 'string' && (props.href.indexOf('/') === 0 || /https?:\/\//.test(props.href))
)

const showNormalLink = computed(
  () => props.href && (isAbsoluteLink.value || props.disabled)
)

const showVueRouterLink = computed(
  () => props.href && !showNormalLink.value
)
</script>

<template>
  <li>
    <!-- Non-router link -->
    <a v-if="showNormalLink" :href="disabled ? undefined : href as string" class="dropdown-item" :class="{ disabled }" v-bind="$attrs">
      <FontAwesomeIcon v-if="icon" :icon="icon" class="me-2" />
      <slot />
    </a>

    <!-- Router link -->
    <RouterLink v-else-if="showVueRouterLink" :to="href!" class="dropdown-item" :class="{ disabled }" v-bind="$attrs">
      <FontAwesomeIcon v-if="icon" :icon="icon" class="me-2" />
      <slot />
    </RouterLink>

    <!-- Plain text -->
    <span v-else class="dropdown-item" :class="{ disabled }" v-bind="$attrs">
      <FontAwesomeIcon v-if="icon" :icon="icon" class="me-2" />
      <slot />
    </span>
  </li>
</template>