<script setup lang="ts">
import { toRef } from "vue";
import { withoutKeys } from '@/helpers'
import type { ZodSchema } from "zod";
import { useUnifiiField } from "@/composables/inputs";

// Prevent attribute inheritance.
defineOptions({ inheritAttrs: false })

export type InputNumberProps = Partial<{
  name: string
  value: string|null|number,
  rules: ZodSchema
  inline: boolean
  required: boolean
  step: number
  errorVariant: 'tooltip' | 'inline'
  align: 'left' | 'right'
}>

const props = withDefaults(
  defineProps<InputNumberProps>(),
  { name: '', value: '', step: 0.1, errorVariant: 'inline', align: 'left' }
)

const $emit = defineEmits<{ 'update:value': [number|undefined] }>()

const {
  errorMessage,
  value: currentValue,
  handleBlur,
  handleChange,
  handleInput
} = useUnifiiField({
  name: toRef(props, 'name'),
  rules: toRef(props, 'rules'),
  initialValue: props.value,
  emitter: (value: number|undefined) => $emit('update:value', value)
})
</script>

<template>
  <div :class="{ 'd-inline-block': inline, 'd-block': !inline }">
    <div class="position-relative flex-nowrap" :class="[
      {
        'input-group': 'prefix' in $slots || 'suffix' in $slots,
        'has-validation': errorMessage,
        [$style['required']]: required
      },
      $style['input-number'],
      $attrs.class
    ]">
      <span class="input-group-text" v-if="'prefix' in $slots"><slot name="prefix" /></span>
      <input
        v-bind="withoutKeys($attrs, ['class'])"
        v-tooltip="errorVariant === 'tooltip' ? { text: errorMessage, variant: 'error' } : undefined"
        inputmode="numeric"
        type="text"
        :name="name"
        :value="currentValue"
        class="form-control"
        :class="{
          'is-invalid': errorMessage,
          'text-end': align === 'right'
        }"
        @change="handleChange"
        @blur="handleBlur"
        @input="handleInput"
      />
      <div class="invalid-tooltip" v-if="errorVariant === 'inline' && errorMessage">{{ errorMessage }}</div>
      <span class="input-group-text" v-if="'suffix' in $slots"><slot name="suffix" /></span>
    </div>
  </div>
</template>

<style module lang="scss">
@use '@/assets/styles/mixins-inputs';
@use '@/assets/mixins/inputs';

.input-number {
  input {
    appearance: textfield;

    // Hide number spinner
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  @include mixins-inputs.fix-error-tooltips-for-inputs;
  @include inputs.apply-prefix-and-suffix-styles;
}

.required {
  @include inputs.apply-required-indicator-styles;
}
</style>
