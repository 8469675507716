<script setup lang="ts">

import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalFooterConfirmation from '@/components/modal/modal-footer-confirmation.vue'
import { computed, ref, toRefs } from 'vue'
import { useApproveInvoice, useGetInvoice, useSendInvoice } from '@/composables/billing/invoices'
import useLoader from '@/composables/loader'
import useNotifications from '@/composables/notifications'
import { useAssetDetails } from '@/composables/assets/assets'
import ContentLoader from '@/components/content-loader.vue'
import { faCheck, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import ModalFooter from '@/components/modal/modal-footer.vue'
import ButtonPrimary from '@/components/button-primary.vue'
import { ErrorResponse } from '@/features/api'

const emit = defineEmits<{ close: [] }>()
const props = defineProps<{ invoiceId: number }>()
const { invoiceId } = toRefs(props)

const { wrap } = useLoader()

const { data: invoiceDetails, isLoading: isLoadingInvoiceDetails, isError: isInvoiceDetailsError } = useGetInvoice(invoiceId)
const { data: assetDetails, isLoading: isLoadingAssetDetails, isError: isAssetDetailsError } = useAssetDetails(() => invoiceDetails.value?.assetCode)
const isLoadingDetails = computed(() => isLoadingAssetDetails.value || isLoadingInvoiceDetails.value)
const hasDetailsError = computed(() => isInvoiceDetailsError.value || isAssetDetailsError.value)

const { mutateAsync: approveInvoiceAsync, isPending: isApprovingInvoice } = useApproveInvoice()
const { mutateAsync: sendInvoiceAsync, isPending: isSendingInvoice } = useSendInvoice()

const { addSuccess } = useNotifications()

const isPending = computed(() => isApprovingInvoice.value || isSendingInvoice.value)
const errorsEncounteredFor = ref<'approval'|'sending'|undefined>()
const errorsEncountered = ref<string[]>([])

function approveAndSend() {
  errorsEncountered.value = []
  errorsEncounteredFor.value = undefined

  wrap(
    async () => {
      try {
        await approveInvoiceAsync(invoiceId)
      } catch (e: unknown) {
        errorsEncounteredFor.value = 'approval'

        throw e
      }

      try {
        await sendInvoiceAsync(invoiceId)
      } catch (e: unknown) {
        errorsEncounteredFor.value = 'sending'

        if (e instanceof ErrorResponse) {
          errorsEncountered.value = e.errors.map(x => x.title)
        }

        throw e
      }
    }
  ).then(
    () => {
      addSuccess({ title: 'Invoice was approved and sent successfully.' })

      emit('close')
    },
  )
}
</script>

<template>
  <ModalContainer>
    <ModalTitle>Approve & Send</ModalTitle>
    <ModalContent :icon="faTriangleExclamation" :icon-variant="hasDetailsError || errorsEncounteredFor ? 'danger' : 'warning'">
      <ContentLoader v-if="isLoadingDetails" loading />

      <p v-else-if="hasDetailsError">This invoice's details could not be loaded. Please try refreshing the page.</p>

      <template v-else>
        <p v-if="!errorsEncounteredFor">
          Are you sure you would like to approve & send Invoice <b>{{ invoiceId }}</b> for <b>{{ assetDetails?.projectName }} ({{ assetDetails?.projectCode }})</b>?
        </p>

        <template v-else>
          <p v-if="errorsEncounteredFor === 'approval'">Your invoice could not be approved.</p>
          <p v-else-if="errorsEncounteredFor === 'sending'">Your invoice was approved, but it could not be sent.</p>

          <template v-if="errorsEncountered.length > 0">
            <p>Please resolve the following errors:</p>
            <ul>
              <li v-for="(error, index) in errorsEncountered" :key="index"
                  v-html="
                    (error.match(/\[/g) ?? []).length === (error.match(/]/g) ?? []).length
                      ? error.replace(/\[/g, '<b>').replace(/]+/g, '</b>')
                      : error
                  " />
            </ul>
          </template>

          <p>Please try contacting our support team if this persists.</p>
          <p>Thank you for your patience.</p>
        </template>
      </template>
    </ModalContent>

    <ModalFooterConfirmation
      v-if="!errorsEncounteredFor"
      @confirm="approveAndSend"
      :pending="isPending"
      @reject="$emit('close')"
    />
    <ModalFooter v-else class="text-end">
      <ButtonPrimary :icon="faCheck" @click="$emit('close')">Okay</ButtonPrimary>
    </ModalFooter>
  </ModalContainer>
</template>

<style scoped lang="scss">

</style>
