import { ListedUserSchema, UserDetailsSchema } from '@/features/users/schemas'
import { assertSchema } from '@/helpers/assert-schema'
import { buildApiUrl, sendGetRequest, Type } from '@/features/api'
import { z } from 'zod'
import { AccessArea, AccessRight } from '@/features/users/constants'

type ProjectAbilities = 'create'
type AllAbilities = `projects:${ProjectAbilities}`

const abilityMap: { [k in AllAbilities]: (permissions: string[]) => boolean | undefined } = {
  'projects:create': x =>
    hasPermission(x, AccessArea.Project, AccessRight.Basic) ||
    hasPermission(x, AccessArea.Administrator)
}

export async function getUserDetails(id: string | 'me') {
  const url = buildApiUrl(Type.Core, `users/${id}`)
  const { data } = await sendGetRequest(url)

  assertSchema(data, UserDetailsSchema)

  return data
}

export async function getUserList(organisationId: number) {
  const url = buildApiUrl(Type.Core, `users?organisation=${organisationId}`)
  const { data } = await sendGetRequest(url)

  assertSchema(data, z.array(ListedUserSchema))

  return data.map(x => ({
    ...x,
    name: `${x.firstName} ${x.lastName}`.trim()
  }))
}

export function hasPermission(permissions: string[], area: AccessArea, ...rights: AccessRight[]) {
  // Converts permissions from array to object of { [AccessArea]: AccessRight[] } form.
  const permissionsMap = permissions.reduce(
    (accumulator, current) => {
      const [area, right] = current.split(':')

      accumulator[area] = accumulator[area] || []
      accumulator[area].push(right)

      return accumulator
    },
    {} as Record<string, string[]>
  )

  // System admin always has permission.
  if (AccessArea.SystemAdministrator in permissionsMap) {
    return true
  }

  // Just check if we have any permissions for the area if no rights are specified.
  if (rights.length < 1) {
    return area in permissionsMap
  }

  // Check exact access rights.
  for (const right of rights) {
    if (permissionsMap[area]?.includes(right)) {
      return true
    }
  }

  // Default to not having permission.
  return false
}

export function hasAbility(permissions: string[], ability: AllAbilities) {
  const fn = abilityMap[ability]

  if (fn) {
    return fn(permissions)
  } else {
    return false
  }
}