import { useQuery } from "@tanstack/vue-query";
import { getAllCustomers, getCustomerTags } from '@/features/assets/customers'
import { createLogger } from "@/helpers/logging";
import { type MaybeRefOrGetter, toValue } from 'vue'

export const useCustomerList = () =>
  useQuery({
    queryKey: ['customers:list'],
    meta: {
      logger: createLogger(`composables/customers/useCustomerList`)
    },
    queryFn: () => getAllCustomers()
  })

export const useProjectTags = (contractFundEntityId: MaybeRefOrGetter<number|undefined|null>) => useQuery({
  queryKey: ['customers', 'tags', contractFundEntityId],
  enabled: () => {
    const actual = toValue(contractFundEntityId)

    return typeof actual === 'number' && actual > 0
  },
  queryFn: () => getCustomerTags(toValue(contractFundEntityId)!),
  select: (data) => ({
    options: data.find(x => x.name.toLowerCase() === 'projects')?.options ?? []
  })
})
