<template>
  <div class="container-sm mt-4 ps-0 pe-0">
    <slot />
  </div>
</template>

<style module lang="scss">
.header {
  min-height: 80px;
  background: #35515F;
  color: #fff;
}
</style>
