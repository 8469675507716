<script setup lang="ts">
import { computed, toRef, watch } from "vue";
import { useField } from "vee-validate";
import { withoutKeys } from "@/helpers";
import type { ZodSchema } from "zod";
import { toTypedSchema } from "@vee-validate/zod";

// Prevent attribute inheritance.
defineOptions({ inheritAttrs: false })

const props = withDefaults(
  defineProps<{
    name?: string
    checked?: boolean
    dark?: boolean
    rules?: ZodSchema
  }>(),
  { name: '' }
)

const emit = defineEmits<{ 'update:checked': [boolean] }>()

const { handleChange, handleBlur, errorMessage, checked } = useField(
  toRef(props, 'name'),
  computed(() => props.rules !== undefined ? toTypedSchema(props.rules) : undefined),
  {
    controlled: !!props.name,
    type: 'checkbox',
    checkedValue: true,
    uncheckedValue: false,
    initialValue: toRef(props, 'checked'),
    syncVModel: false,
  }
)

watch(
  checked!,
  (v) => {
    emit('update:checked', v)
  }
)
</script>

<template>
  <div class="form-check" :class="[$style['input-checkbox'], { [$style['input-checkbox-dark']]: dark }, $attrs.class]">
    <input
      v-bind="withoutKeys($attrs, ['class'])"
      :name="name"
      :checked="checked"
      class="form-check-input"
      :class="{ 'is-invalid': errorMessage }"
      type="checkbox"
      v-tooltip="{ text: errorMessage, variant: 'error' }"
      @change="handleChange"
      @blur="handleBlur"
    />
  </div>
</template>

<style module lang="scss">
@use "@/assets/styles/mixins-inputs";

@import "@/assets/variables";
@import "@/assets/bootstrap-variables";

.input-checkbox {
  @include mixins-inputs.fix-error-tooltips-for-checkboxes;

  display: inline;
  padding: 0;
  margin: 0;
  min-height: auto;
  line-height: 0;

  :global(.form-check-input) {
    float: none;
    margin-left: 0;
  }
}

.input-checkbox-dark {
  :global {
    .form-check-input, .form-check-input:focus, .form-check-input:checked {
      border-color: #fff;
    }
  }
}

</style>
