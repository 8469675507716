<script setup lang="ts" generic="T extends object">
import { computed, onMounted, ref, toRef } from "vue";
import createDebug from 'debug'
import { isObject, objectHasKey } from "@/helpers";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown, faChevronUp, faSearch } from "@fortawesome/pro-regular-svg-icons";
import type { ZodSchema } from "zod";
import InputText from "@/components/input-text.vue";
import 'bootstrap/js/dist/dropdown'
import { useUnifiiField } from "@/composables/inputs";

const debug = createDebug('components:InputSelect')


type ItemValue = any
type Item = { text: string, value: ItemValue }

const props = withDefaults(
  defineProps<{
    items: Readonly<T[]>
    itemText: (keyof T & string) | ((item: T, index: number) => ItemValue)
    itemValue: (keyof T & string) | ((item: T, index: number) => ItemValue)
    disabled?: boolean
    name?: string,
    searchable?: boolean
    selected?: ItemValue[] | ItemValue
    multiple?: boolean
    placeholder?: string
    displayLimit?: number
    maxSelected?: number
    rules?: ZodSchema
    inline?: boolean
    errorVariant?: 'inline' | 'tooltip'
    required?: boolean
  }>(), {
    name: '',
    displayLimit: 3,
    placeholder: 'Please select',
    multiple: false,
    disabled:false,
    errorVariant: 'inline'
  }
)

const emit = defineEmits<{
  'update:selected': [typeof props.multiple extends true ? ItemValue[] : ItemValue]
}>()

const $root = ref<HTMLDivElement>()
const $search = ref<InstanceType<typeof InputText>>()

const isExpanded = ref(false)

const { validate, setValue, errorMessage, value: currentValue } = useUnifiiField({
  name: toRef(props, 'name'),
  rules: toRef(props, 'rules'),
  initialValue: toRef(props, 'selected'),
})

const searchValue = ref('')
const searchValueTransformed = computed(() => searchValue.value.trim())

function extractText(item: T, index: number): string|undefined {
  const { itemText } = props

  if (typeof itemText === 'function') {
    return itemText(item, index)
  }

  if (isObject(item) && objectHasKey(item, itemText)) {
    return item[itemText]?.toString() || ''
  }

  debug(`Provided [textKey] not found in item.`, { itemText, item, index })

  return undefined
}

function extractValue(item: T, index: number): ItemValue|undefined {
  const { itemValue } = props

  if (typeof itemValue === 'function') {
    return itemValue(item, index)
  }

  if (isObject(item) && objectHasKey(item, itemValue)) {
    return item[itemValue]
  }

  debug(`Provided [valueKey] not found in item.`, { itemValue, item, index })

  return undefined
}

const allItems = computed(
  () => (props.items || []).map(
    (item, index): Item|undefined => {
      const value = extractValue(item, index)
      const text = extractText(item, index)

      if (text === undefined) {
        debug(`Ignoring item with index [${index}]: item display text couldn't be extracted.`, { item })

        return undefined
      }

      if (value === undefined) {
        debug(`Ignoring item with index [${index}]: item value couldn't be extracted.`, { item })

        return undefined
      }

      return { text: text.toString(), value }
    }
  ).filter(
    (x): x is Item => x !== undefined
  )
)

const itemsForDropdown = computed(
  () => {
    if (searchValueTransformed.value === '') {
      return [...allItems.value]
    }

    return allItems.value.filter(({ text }) => text.toLowerCase().includes(searchValueTransformed.value))
  }
)

const selectedValues = computed<ItemValue[]>(
  () => {
    const selected = currentValue.value

    if (selected === undefined) {
      return []
    }

    return Array.isArray(selected)
      ? selected
      : [selected]
  }
)

function isSelected(value: ItemValue) {
  return selectedValues.value.indexOf(value) > -1
}

function toggleSelected(value: ItemValue) {
  let values: ItemValue[] = []

  // If it's not a multiselect, then set the selected value.
  if (!props.multiple) {
    debug('Setting selected value [!multiple]')

    values = [value]
  }
  // Otherwise, toggle the selected indexes.
  else {
    const selectedValuesCopy = [...selectedValues.value]
    const index = selectedValuesCopy.indexOf(value)
    const { maxSelected } = props

    if (index > -1) {
      debug(`Removing selected value.`, { index, value })

      selectedValuesCopy.splice(index, 1)
    } else if (maxSelected !== undefined && selectedValuesCopy.length >= maxSelected) {
      debug('Ignoring item selection. Max number of selected items has been reached.', { maxSelected, selected: selectedValuesCopy })

      return
    } else {
      debug('Selecting item.', { value })

      selectedValuesCopy.push(value)
    }

    values = selectedValuesCopy
  }

  const v = props.multiple
    ? values
    : values[0]

  setValue(v)

  validate().then(() => emit('update:selected', v))
}

const buttonText = computed(
  () => {
    const { displayLimit, placeholder } = props

    if (allItems.value.length > 0 && selectedValues.value.length === allItems.value.length && props.multiple) {
      return `All selected (${allItems.value.length})`
    }

    const selectedText = allItems.value
      .filter(item => isSelected(item.value))
      .map(item => item.text)

    if (selectedText.length > displayLimit) {
      return `${selectedText.length} selected`
    }

    if (selectedText.length > 0) {
      return selectedText.join(', ')
    }

    return placeholder
  }
)

function preventCheckboxTogglingIfNotSelected(event: Event, value: ItemValue) {
  if (event.target instanceof HTMLInputElement) {
    event.target.checked = isSelected(value)
  }
}

onMounted(
  () => {
    $root.value?.addEventListener('show.bs.dropdown', () => isExpanded.value = true)
    $root.value?.addEventListener('hide.bs.dropdown', () => {
      isExpanded.value = false

      $search.value?.reset()
    })
  }
)
</script>

<template>
  <div
    :class="[$style['input-select'], { [$style['input-select-multiple']]: multiple, [$style['input-select-single']]: !multiple, [$style.inline]: inline }]"
    ref="$root">
    <div class="dropdown disabled" :class="{ 'is-invalid': errorMessage }">
      <button class="d-block btn btn-outline-secondary text-start"
              :class="{ [$style.required]: required }"
              data-bs-toggle="dropdown"
              :disabled="disabled">
        <span class="d-flex align-items-center justify-content-between" v-tooltip="errorVariant === 'tooltip' ? { text: errorMessage, variant: 'error' } : undefined">
          {{ buttonText }}
          <FontAwesomeIcon :icon="isExpanded ? faChevronUp : faChevronDown" class="ms-2" />
        </span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" @click="multiple && $event.stopPropagation()">
        <li v-if="searchable" class="dropdown-item" :class="$style.search">
          <InputText v-model:value="searchValue" :class="$style['search-input']" ref="$search">
            <template #suffix>
              <FontAwesomeIcon class="small" :icon="faSearch" />
            </template>
          </InputText>
        </li>
        <li class="dropdown-item disabled" v-if="itemsForDropdown.length === 0">
          {{ searchValueTransformed !== '' ? 'No items found.' : 'No items available.' }}
        </li>
        <template v-else>
          <li :class="{ active: isSelected(item.value) }" class="dropdown-item d-flex align-items-center"
            v-for="(item, index) in itemsForDropdown" :key="index" @click="toggleSelected(item.value)"
            :data-item-index="index">
            <input type="checkbox" class="mt-0 form-check-input" v-if="multiple" :value="item.value"
              :checked="isSelected(item.value)" @click="preventCheckboxTogglingIfNotSelected($event, item.value)" />
            <span :class="{ 'ms-2': multiple }">{{ item.text }}</span>
          </li>
        </template>
      </ul>
    </div>

    <div class="invalid-tooltip" v-if="errorVariant === 'inline' && errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<style module lang="scss">
@use "@/assets/mixins-form" as mixins;
@use "@/assets/mixins/inputs";

@import "@/assets/variables";
@import "@/assets/bootstrap-variables";

.search {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: #{-1 * $dropdown-padding-y};

  &:hover, &:focus, &:active {
    background-color: transparent !important;
  }

  :global(.input-group-text) {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .search-input :global(.form-control), .search-input :global(.input-group-text) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

.input-select {
  width: 100%;
  cursor: default;
  position: relative;

  @include mixins.invalid-tooltip;

  :global {
    .dropdown-menu {
      max-height: 500px;
      overflow-y: auto;
    }

    .dropdown > .btn.show {
      border-color: $input-focus-border-color;
    }

    .is-invalid > button, .is-invalid > .btn.show {
      border-color: var(--#{$prefix}danger);
    }

    .dropdown > .btn {
      --#{$prefix}btn-padding-x: 10px;
      --#{$prefix}btn-padding-y: 6px;

      --#{$prefix}btn-color: #{$body-color};
      --#{$prefix}btn-bg: #fff;
      --#{$prefix}btn-hover-bg: #fff;
      --#{$prefix}btn-hover-color: var(--#{$prefix}btn-color);
      --#{$prefix}btn-hover-border-color: var(--#{$prefix}btn-border-color);
      --#{$prefix}btn-border-color: #d1d1d1;
      --#{$prefix}btn-disabled-border-color: #d1d1d1;
      --#{$prefix}btn-active-bg: var(--#{$prefix}btn-bg);
      --#{$prefix}btn-active-color: #{$body-color};
      --#{$prefix}btn-active-border-color: #d1d1d1;

      position: relative;
      width: 100%;
      display: none;
      transition: none;
    }

    .dropdown > .btn:hover {
      cursor: default;
    }
  }
}

.inline {
  display: inline-block;
  width: auto;

  :global(.dropdown-menu) {
    --bs-dropdown-min-width: auto;
  }
}

.input-select-single {
  :global {
    .dropdown-item:hover, .dropdown-item.active {
      background-color: #e0e0e0;
      color: #000;
    }
  }
}

.input-select-multiple {
  :global {
    .dropdown-item.active {
      color: var(--bs-dropdown-color);
      background-color: transparent;
    }

    .dropdown-item.active:hover, .dropdown-item:not(:local(.search)):active {
      background-color: #e0e0e0;
      color: #000;
    }

    .form-check-input:focus {
      border-color: var(--#{$prefix}border-color);
    }
  }
}

.required {
  @include inputs.apply-required-indicator-styles(":global(.show)");
}
</style>
