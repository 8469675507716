export default {
  login: 'login',
  termsAndConditions: 'termsAndConditions',

  // Projects
  projectsDashboard: 'projects.dashboard',

  // Assets
  assetInvoicesDashboard: 'assetManagement.invoicing.invoiceDashboard',
  invoiceSetup: 'assetManagement.invoicing.invoiceSetup',
  assetSettings: 'assetManagement.assets.assetSettings',
  invoice: 'assetManagement.invoicing.invoice',
  assetMetersDashboard: 'assetManagement.assets.metersDashboard',
}
