import { computed, ref } from "vue";
import createDebugger from 'debug'

const debug = createDebugger('composables:useLoader')
const stackSize = ref(0)

export default function useLoader() {
  const isActive = computed(() => stackSize.value > 0)

  const show = () => {
    stackSize.value++

    debug(`Showing loader. Current stack = ${stackSize.value}`)
  }

  const hide = () => {
    if (stackSize.value > 0) {
      stackSize.value--

      debug(`Hiding loader. Current stack = ${stackSize.value}`)
    }
  }

  const remove = () => {
    stackSize.value = 0

    debug(`Removing loader. New stack size = ${stackSize.value}`)
  }

  const wrap = async <T>(fn: () => T) => {
    debug(`Wrapping function.`)
    show()

    try {
      return await fn()
    } finally {
      hide()

      debug(`Finished wrapping function.`)
    }
  }

  return {
    isActive,
    show,
    hide,
    remove,
    wrap
  }
}
