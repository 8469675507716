import createDebugger, { type Debugger } from 'debug'

type Context = Record<string, any>
export type Logger = {
  debug(message: string, context?: Context): void
  info(message: string, context?: Context): void
  warning(message: string, context?: Context): void
  error(message: string, context?: Context): void
  critical(message: string, context?: Context): void
  wrapError<TFunction extends (...args: readonly any[]) => any>(fn: TFunction, message?: string, context?: Context): (...params: Parameters<TFunction>) => ReturnType<TFunction>
}

const loggerMap = new Map<string, Debugger>()

function getLogger(level: string, namespace: string) {
  const key = `${level}:${namespace}`

  if (loggerMap.has(key)) {
    return loggerMap.get(key)!
  }

  const debugFn = createDebugger(key)

  loggerMap.set(key, debugFn)

  return debugFn
}

function buildMessage(namespace: string, level: string, message: string, context: Context) {
  return {
    message,
    namespace,
    level,
    timestamp: new Date(),
    context,
  }
}

export function createLogger(namespace: string): Logger {
  return {
    debug(message, context = {}) {
      getLogger('debug', namespace)(
        buildMessage(namespace, 'debug', message, context)
      )
    },

    info(message, context = {}) {
      getLogger('info', namespace)(
        buildMessage(namespace, 'info', message, context)
      )
    },

    warning(message, context = {}) {
      getLogger('warning', namespace)(
        buildMessage(namespace, 'warning', message, context)
      )
    },

    error(message, context = {}) {
      getLogger('error', namespace)(
        buildMessage(namespace, 'error', message, context)
      )
    },

    critical(message, context = { }) {
      getLogger('critical', namespace)(
        buildMessage(namespace, 'critical', message, context)
      )
    },

    wrapError(fn, message = undefined, context = {}) {
      const errorHandler = (error: unknown) => {
        this.error(message || 'Encountered unexpected error', { ...context, error })

        throw error
      }

      return (...args) => {
        try {
          const result = fn(...args)

          return typeof result?.then === 'function'
            ? result.then(null, errorHandler)  // Is PromiseLike. Can be handled in the callback.
            : result                           // Not a promise. The error is caught in the catch clause.
        } catch (error: unknown) {
          errorHandler(error)
        }
      }
    }
  } as const
}
