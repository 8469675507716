import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { getAssetSettings, getAssetSettingsLookups, getAssetCustomers} from '@/features/assets/get-asset-settings'
import { toValue, type MaybeRefOrGetter } from 'vue'
import type { UpdateAssetSettings } from '@/features/assets/schemas'
import { saveAssetSettings } from '@/features/assets/save-asset-settings'
import { createLogger } from "@/helpers/logging";
import { getAssetDetails } from '@/features/assets/assets'
import { updateMeterConfiguration } from '@/features/assets/update-meter-display-config'

export function useAssetDetails(assetCode: MaybeRefOrGetter<number | undefined | null>) {
  return useQuery({
    queryKey: ['assets:details', assetCode],
    queryFn: () => getAssetDetails(toValue(assetCode)!),
    meta: {
      logger: createLogger('composables/assets/useAssetDetails')
    },
    enabled() {
      const asValue = toValue(assetCode)

      return !!asValue && asValue > 0
    },
  })
}

export function useAssetSettings(
  assetCode: MaybeRefOrGetter<number | undefined>
) {
  return useQuery({
    queryKey: ['assets:settings', assetCode],
    meta: {
      logger: createLogger(`composables/assets/useAssetSettings`)
    },
    queryFn: () =>
      getAssetSettings(toValue(assetCode)!),
    enabled() {
      const value = toValue(assetCode)

      return value !== undefined && value > 0
    }
  })
}

export function useAssetSettingsLookups() {
  return useQuery({
    queryKey: ['assets:settingsLookups'],
    meta: {
      logger: createLogger(`composables/assets/useAssetSettingsLookups`)
    },
    queryFn: () =>
      getAssetSettingsLookups()
  })
}

export function useAssetCustomers(contractFundEntityId: MaybeRefOrGetter<number|undefined>) {
  return useQuery({
    queryKey: ['assets:customers', contractFundEntityId],
    meta: {
      logger: createLogger(`composables/assets/useAssetCustomers`)
    },
    enabled() {
      const v = toValue(contractFundEntityId)
      return v !== undefined && v > 0
    },
    queryFn: () =>
      getAssetCustomers(toValue(contractFundEntityId)!)
  })
}

export function useSaveAssetSettings(assetCode: MaybeRefOrGetter<number | undefined>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (settings: MaybeRefOrGetter<UpdateAssetSettings>) => saveAssetSettings(toValue(assetCode)!, toValue(settings)),
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['assets'] }),
        queryClient.invalidateQueries({ queryKey: ['assets:settings', assetCode] }),
        queryClient.invalidateQueries({ queryKey: ['invoices:config', assetCode] }),
      ])
    },
  })
}

type MeterConfig = {
  id: number,
  hide: boolean
}

export function useSaveMeterConfiguration(assetCode: MaybeRefOrGetter<number>){
  return useMutation({
    mutationFn: (meterConfigs: MeterConfig []) => updateMeterConfiguration(toValue(assetCode), meterConfigs)
  })
}
