import { useField } from "vee-validate";
import { computed, isRef, type MaybeRefOrGetter, nextTick, toValue, watch } from 'vue'
import type { ZodSchema } from "zod";
import { toTypedSchema } from "@vee-validate/zod";

type UseFieldParameters = Parameters<typeof useField>

function getElementValue(target: EventTarget|null|undefined) {
  if (target instanceof HTMLInputElement) {
    if (target.type === 'number' || target.type === 'range' || (target.type === 'text' && target.inputMode === 'numeric')) {
      const asFloat = Number(target.value)  // This is preferred over toFloat or toInt, as Number() will yield
                                                     // NaN if there is a non-numeric value _anywhere_.

      if (!isNaN(target.valueAsNumber)) {
        return target.valueAsNumber
      } else if (!isNaN(asFloat)) {
        return asFloat
      } else {
        return target.value
      }
    } else if (target.type === 'date') {
      return target.valueAsDate || undefined
    }
    return target.value
  }

  if (target instanceof HTMLTextAreaElement) {
    return target.value
  }

  return undefined
}

export function useUnifiiField<TValue>({
  name,
  rules,
  initialValue,
  emitter
} : {
  name: UseFieldParameters[0]
  rules: MaybeRefOrGetter<ZodSchema|undefined>
  initialValue?: MaybeRefOrGetter<TValue>
  emitter?: (value: any, element: HTMLInputElement|HTMLTextAreaElement) => void
}) {
  const computedRules = computed(
    () => {
      const rulesValue = toValue(rules)

      return rulesValue
        ? toTypedSchema(rulesValue)
        : undefined
    }
  )

  const field = useField(
    name,
    computedRules,
    {
      syncVModel: false,
      initialValue,
      controlled: toValue(name) !== ''
    }
  )

  if (isRef(initialValue)) {
    watch(
      initialValue,
      (newValue) => {
        field.setValue(newValue, false)
      }
    )
  }

  return {
    ...field,
    handleChange(e: Event) {
      const target = e.target
      const value = getElementValue(target)

      field.setValue(value, true)
    },
    handleInput(event: Event) {
      const { validate, meta, setValue } = field
      const value = getElementValue(event.target)

      // Trigger the change on the field.
      setValue(value, false)

      // Wait for the virtual DOM to respond to the change.
      nextTick().then(
        () => {
          if (meta.touched || meta.validated) {
            return validate()
          }
          else if (meta.dirty) {
            return validate({ mode: 'silent' })
          }
        }
      ).then(
        result => {
          if (emitter === undefined) {
            return
          }

          const isValid = result !== undefined
            ? result.valid
            : meta.valid

          if (isValid && (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement)) {
            emitter(value, event.target)
          }
        }
      )
    }
  }
}
