<script setup lang="ts">
import { computed, inject } from "vue";
import { SortingInjectionKey } from '@/components/data-table.vue'
import { faCaretDown as faCaretDownActive, faCaretUp as faCaretUpActive } from "@fortawesome/pro-solid-svg-icons";
import { faCaretDown as faCaretDownInactive, faCaretUp as faCaretUpInactive } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

const props = withDefaults(
  defineProps<{
    name?: string
    context?: boolean
    sort?: boolean
  }>(),
  { sort: true }
)

const { sortingMap, toggleSorting: providedToggleSorting } = inject(SortingInjectionKey)!
const isMultiSort = computed(() => Object.keys(sortingMap.value).length > 1)

function toggleSorting(e: MouseEvent) {
  if (props.name && props.sort && e instanceof MouseEvent) {
    providedToggleSorting(props.name, e.ctrlKey)
  }
}
</script>

<template>
  <th :class="[$style['header'], { [$style.sortable]: name && sort, [$style.context]: context }]" @click="toggleSorting">
    <slot>
      {{ (name || '').replace(/([a-z])([A-Z])/, (match, lc, uc) => lc + ' ' + uc).replace(/^[a-z]|\bId\b/g, m => m.toUpperCase()) }}
    </slot>

    <template v-if="name && sort">
      <FontAwesomeLayers class="ms-1">
        <template v-if="name in sortingMap">
          <FontAwesomeIcon
            :icon="sortingMap[name]!.direction === 'asc' ? faCaretUpActive : faCaretUpInactive"
            transform="up-3 shrink-2" />
          <FontAwesomeIcon
            :icon="sortingMap[name]!.direction === 'asc'
                      ? faCaretDownInactive
                      : faCaretDownActive
                  "
            transform="down-3 shrink-2" />
        </template>
        <template v-else>
          <FontAwesomeIcon :icon="faCaretUpInactive" transform="up-3 shrink-2" />
          <FontAwesomeIcon :icon="faCaretDownInactive" transform="down-3 shrink-2" />
        </template>
      </FontAwesomeLayers>

      <span v-if="isMultiSort && name in sortingMap" class="ms-1">
        ({{ sortingMap[name]!.index + 1 }})
      </span>
    </template>
  </th>
</template>

<style module lang="scss">
.header {
  -moz-user-select: none;
}

.sortable {
  --fa-secondary-opacity: 0.25;
  cursor: pointer;

  &:active {
    background-color: rgba(0, 0, 0, 0.025);
  }
}

.context {
  width: 1px;
  white-space: nowrap;
}
</style>
