<script setup lang="ts" generic="T extends unknown">
import { toRef } from 'vue'
import { useField } from 'vee-validate'

const props = withDefaults(
  defineProps<{
    options: { text: string, value: T }[]
    selected?: T
    size?: 'small' | 'normal' | 'large'
    disabled?: boolean
    name?: string
  }>(),
  { size: 'small', disabled: false, name: '' }
)

const emit = defineEmits<{ 'update:selected': [T] }>()

const { value: currentValue, setValue, errorMessage } = useField(
  toRef(props, 'name'),
  undefined,
  {
    syncVModel: false,
    controlled: props.name !== '',
    initialValue: props.selected,
  }
)

function selectOption(index: number) {
  const { value } = props.options[index]

  setValue(value)

  emit('update:selected', value)
}

</script>

<template>
  <div class="btn-group" :class="{ 'btn-group-sm': size === 'small', 'btn-group-lg': size === 'large', [$style.error]: errorMessage }">
    <button
      v-for="(option, index) in options"
      type="button"
      :key="index"
      :disabled="disabled"
      @click="selectOption(index)"
      :class="[$style.btn, { [$style.active]: currentValue === option.value, }]">{{
        option.text
    }}</button>
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.error {
  .btn {
    border-top-color: $form-feedback-invalid-color;
    border-bottom-color: $form-feedback-invalid-color;

    &:first-child {
      border-left-color: $form-feedback-invalid-color;
    }

    &:last-child {
      border-right-color: $form-feedback-invalid-color;
    }
  }
}

.btn {
  color: $body-color;
  padding: 3px 10px;
  background: $white;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: $input-border-color;

  //&.error {
  //  border-color: $form-feedback-invalid-color;
  //
  //  &:
  //}

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.active {
    border-width: 1px;
  }

  &:hover:not(.active) {
    background: #f9f9f9;
  }
}

.active + .btn {
  border-left-width: 0;
}

.active {
  background: $yellow;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}
</style>
