import { z } from 'zod'

export type UserFlags = z.infer<typeof UserFlagsSchema>
export type UserDetails = z.infer<typeof UserDetailsSchema>
export type ListedUser = z.infer<typeof ListedUserSchema>

export const UserFlagsSchema = z.object({
  isTechnicalReviewer: z.boolean(),
  isValidator: z.boolean()
})

export const UserDetailsSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  emailAddress: z.string(),
  permissions: z.array(z.string()),
  organisationId: z.number().int().gt(0)
})

export const ListedUserSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  emailAddress: z.string(),
  permissions: z.array(z.string())
})
