<script setup lang="ts">
import ModalContainer from "@/components/modal/modal-container.vue";
import ModalTitle from "@/components/modal/modal-title.vue";
import ModalContent from "@/components/modal/modal-content.vue";
import { computed, toRef } from "vue";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { format } from "date-fns/format";
import { DateFormats } from "@/helpers/formatting";
import ModalFooter from "@/components/modal/modal-footer.vue";
import type { InvoiceActivity } from "@/features/billing/schemas";
import { useInvoiceActivity } from "@/composables/billing/invoices";
import ButtonSecondary from "@/components/button-secondary.vue";
import DataTable from "@/components/data-table.vue";
import DataTableCell from "@/components/data-table-cell.vue";
import DataTableHeader from "@/components/data-table-header.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import ContentLoader from "@/components/content-loader.vue";
import useUserList from "@/composables/users/user-list";
import useAuthenticatedUserDetails from "@/composables/users/authenticated-user-details";

const props = defineProps<{ invoiceId: number, title: string }>()
defineEmits<{ 'close': [] }>()

const { data: authenticatedUser, isLoading: isLoadingAuthenticatedUser } = useAuthenticatedUserDetails()

const {
  data: activityLog,
  isFetching: isFetchingActivityLog,
  isError: activityLogHasError
} = useInvoiceActivity(toRef(props, 'invoiceId'))

const {
  data: userList,
  isFetching: isFetchingUserList,
  isError: userListHasError
} = useUserList(() => authenticatedUser.value?.organisationId)

const userListAsMap = computed(
  () => (userList.value || []).reduce(
    (accumulation, current) => {
      accumulation[current.id] = current.name

      return accumulation
    },
    { } as Record<string, string>
  )
)

const isFetching = computed(
  () => isFetchingUserList.value || isFetchingActivityLog.value || isLoadingAuthenticatedUser.value
)

const isError = computed(
  () => activityLogHasError.value || userListHasError.value
)
</script>

<template>
  <ModalContainer>
    <ModalTitle>
      <div class="d-flex justify-space-between">
        <span>Activity - {{ title }}</span>
      </div>
    </ModalTitle>
    <ModalContent>
      <ContentLoader :loading="isFetching" variant="table">
        <HeroSplashError v-if="isError" />

        <DataTable v-else :records="activityLog!">
          <template #headers>
            <DataTableHeader>Activity</DataTableHeader>
            <DataTableHeader>User</DataTableHeader>
            <DataTableHeader>Date</DataTableHeader>
          </template>

          <template #record="{ record } : { record: InvoiceActivity }">
            <DataTableCell>{{ record.activity }}</DataTableCell>
            <DataTableCell>{{ record.userId ? userListAsMap[record.userId] || record.userId : 'Unknown' }}</DataTableCell>
            <DataTableCell>{{ format(record.date, DateFormats.UserFriendly) }}</DataTableCell>
          </template>
        </DataTable>
      </ContentLoader>
    </ModalContent>
    <ModalFooter class="text-end">
      <ButtonSecondary @click="$emit('close')" :icon="faTimes">Close</ButtonSecondary>
    </ModalFooter>
  </ModalContainer>
</template>

<style scoped lang="scss">

</style>