<script setup lang="ts">
import { ref, watch } from 'vue';
import { useAssetSettings } from '@/composables/assets/assets';
import Card from "@/components/Card/card-container.vue";
import CardContent from "@/components/Card/card-content.vue";
import CardHeader from "@/components/Card/card-header.vue";
import CardHeaderItem from '@/components/Card/card-header-item.vue';
import InputCheckbox from "@/components/input-checkbox.vue";
import { type InvoiceTemplate } from "@/features/assets/schemas";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";

const props = defineProps<{ assetCode: number }>();
const { data: assetData, isLoading: assetLoading, error: assetError } = useAssetSettings(props.assetCode);
const invoiceTemplates = ref<InvoiceTemplate[]>([]);
watch(
    assetData,
    (newAssetData) => {
        if (newAssetData === undefined) {
            return
        }
        if (invoiceTemplates.value.length == 0) {
            invoiceTemplates.value = newAssetData.invoiceTemplates || [];
        }
    }, { immediate: true });
</script>
<template>
    <Card class="invoice-template">
        <CardHeader>
            <CardHeaderItem>Invoice Template</CardHeaderItem>
        </CardHeader>
        <CardContent>
            <ContentLoader :loading="assetLoading" variant="overlay">
                <HeroSplashError v-if="assetError" />
                <table class="table table-no-bottom-border">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Supplied</th>
                            <th scope="col">Active</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(invoiceTemplate, index) in invoiceTemplates" :key="invoiceTemplate.id"
                            :class="{ 'last-row': index === invoiceTemplates?.length - 1 }">
                            <th scope="row">{{ invoiceTemplate.description }}</th>
                            <td>System</td>

                            <td>
                                <InputCheckbox v-model:checked="invoiceTemplate.active" disabled/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ContentLoader>
        </CardContent>
    </Card>

</template>
<style scoped lang="scss">
.invoice-template {
    width: 407px;
    min-width: 125px;
}
</style>
