export function isObject(obj: any): obj is typeof obj & {} {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
}

export async function delay(duration: number) {
  return new Promise(r => setTimeout(r, duration))
}

export async function randomDelayBetween(min: number, max: number) {
  return new Promise(r => setTimeout(r, Math.random() * (max - min) + min))
}

export function objectHasKey<T extends object>(obj: T, key: string): key is typeof key & keyof T {
  return key in obj
}

// Backwards compatibility.
export const withoutKeys = objectWithoutKeys

export function objectWithoutKeys<O extends object, K extends (keyof O)|string>(obj: O, keys: K[]): Omit<O, K> {
  if (!isObject(obj)) {
    return obj
  }

  const shallowCopy = { ...obj }

  for (const key of keys) {
    // @ts-ignore
    delete shallowCopy[key]
  }

  return shallowCopy
}

// Backwards compatibility.
export const withOnlyKeys = objectWithKeys

export function objectWithKeys<O extends object, K extends keyof O>(obj: O, keys: K[]): Pick<O, K> {
  const copied = <Pick<O, K>>{ }

  for (const key of keys) {
    copied[key] = obj[key]
  }

  return copied
}

export function assertValue<TData>(data: TData|undefined): asserts data is TData {
  if (data === undefined) {
    throw new Error("Query has error")
  }
}

export function roundToPrecision<T extends number|undefined>(value: T, precision: number): T {
  if (typeof value === 'number') {
    const multiplyBy = Math.pow(10, Math.abs(precision))

    return Math.round(value * multiplyBy) / multiplyBy as T
  }

  return undefined as T
}

export const roundToMainCurrencyPrecision = <T extends number|undefined>(value: T) => roundToPrecision(value, 7)
export const roundToFractionalCurrencyPrecision = <T extends number|undefined>(value: T) => roundToPrecision(value, 5)
