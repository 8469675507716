<script setup lang="ts">
// See https://codepen.io/team/css-tricks/pen/dNQKXv for inspiration on the styling.
import useNotifications, { type Notification } from "@/composables/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleCheck, faTriangleExclamation, faCircleExclamation, faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { computed } from "vue";
import watchDebug from "@/helpers/watch-debug";

const { notifications, shift } = useNotifications()

const typeMap: { [k in Notification['type']]: { icon: IconDefinition, color: string } } = {
  'info': {
    icon: faCircleInfo,
    color: '#365160'
  },
  'error': {
    icon: faCircleExclamation,
    color: '#FD5624'
  },
  'warning': {
    icon: faTriangleExclamation,
    color: '#FEE351'
  },
  'success': {
    icon: faCircleCheck,
    color: '#28A09C'
  },
}

const current = computed(() => notifications.value[0])

watchDebug(current, 'Current toast notification has changed')
</script>

<template>
    <Teleport to="body">
      <Transition>
        <div v-if="notifications.length > 0" :class="[$style.container]" :key="JSON.stringify(current)">
          <div class="d-flex align-items-center" :class="[$style.notification, { [$style['auto-hide']]: true }]" :style="{ '--color': typeMap[current.type].color, '--duration': `${current.hideDelay || 0}ms` }">
            <FontAwesomeIcon :icon="typeMap[current.type].icon" class="me-3" :style="{ color: typeMap[current.type].color }" />
            <div class="flex-grow-1">
              <b class="d-block" :class="{ 'mb-1': current.message }" v-if="current.title">{{ current.title }}</b>
              <div v-if="current.message">
                <div v-if="current.allowHtml" v-html="current.message" />
                <div v-else>{{ current.message }}</div>
              </div>
            </div>
            <span @click="shift()" :class="$style.close" class="ms-3"><FontAwesomeIcon :icon="faTimes" /></span>
            <div :class="[$style.timer, $style[`timer-${current.type}`]]" v-if="current.autoHide"></div>
          </div>

          <div v-if="notifications.length > 1" :class="$style.remaining" class="mt-1">
            <span>+{{ notifications.length - 1}} more</span>
          </div>
        </div>
      </Transition>
    </Teleport>
</template>

<style module lang="scss">
@import "@/assets/variables";

.container {
  z-index: 10;
  position: fixed;
  left: 50%;
  translate: -50%;
  top: 20px;
  margin-left: auto;
  margin-right: auto;

  &:global {
    &.v-enter-from, &.v-leave-to {
      opacity: 0;
      margin-top: -30px;
    }

    &.v-enter-active, &.v-leave-active {
      transition: all 200ms ease;
    }

    &.v-enter-to, &.v-leave-from {
      opacity: 1;
      margin-top: 0;
    }

    &.v-leave-to {
      scale: 0.9;
    }
  }
}

.remaining {
  text-align: center;

  > span {
    display: inline-block;
    line-height: 1;
    border-radius: 3px;
    padding: 0.2rem 0.4rem;
    font-size: 0.65rem;

    border: 1px solid #d0d0d0;
    color: $secondary;
    background: #e9e9e9;
  }
}

.notification {
  position: relative;
  background: white;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;

  padding: 10px 15px;
  width: 395px;
  border-color: var(--color);
  border-style: solid;
  border-width: 1px 1px 4px 1px;

  .close {
    cursor: pointer;
    align-self: start;

    svg {
      font-size: initial;
    }
  }

  svg {
    font-size: 24px;
  }
}

.timer {
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  background: rgba(0, 0, 0, 0.2);

  animation-duration: var(--duration);
  animation-timing-function: linear;
  animation-name: timer;
  animation-iteration-count: 1;
}

.timer-success, .timer-error {
  background: rgba(0, 0, 0, 0.2);
}

.timer-warning {
  background: rgba(0, 0, 0, 0.08);
}

.timer-info {
  background: rgba(255, 255, 255, 0.2);
}

@keyframes timer {
  from {
    width: 100%;
  }
  1% {
    border-bottom-right-radius: 0;
  }
  to {
    width: 0;
  }
}
</style>