<script setup lang="ts">

</script>

<template>
  <span :class="$style['input-label']"><slot /></span>
</template>

<style module lang="scss">
.input-label {
  font-weight: 500;
}
</style>
