<script setup lang="ts">

</script>

<template>
  <div :class="$style['title']"><slot /></div>
</template>

<style module lang="scss">
.title {
  margin: -25px -25px 25px -25px;
  padding: 25px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>