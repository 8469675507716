import { z } from 'zod'
import { CustomerSchema, CustomerTagSchema } from '@/features/assets/schemas'
import { buildApiUrl, sendGetRequest, Type } from '@/features/api'
import { assertSchema, assertSchemaAndReturn } from '@/helpers/assert-schema'

export async function getAllCustomers() {
  const url = buildApiUrl(Type.AssetManagement, 'lookup/asset/customers')
  const { data } = await sendGetRequest(url)

  assertSchema(data, z.array(CustomerSchema))

  return data
}

export const getCustomerTags = (contractFundEntityId: number) =>
  sendGetRequest(
    buildApiUrl(Type.AssetManagement, `customers/contract-fund-entities/${contractFundEntityId}/tracking-tags`)
  ).then(
    ({ data }) => assertSchemaAndReturn(data, z.array(CustomerTagSchema))
  )
