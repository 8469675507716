<script setup lang="ts">
import type { RouterLinkProps } from 'vue-router'

defineOptions({ inheritAttrs: false })
defineProps<RouterLinkProps & { disabled?: boolean }>()

const externalLinkRegex = /^https?:\/\//
</script>

<template>
  <a v-if="disabled" v-bind="$attrs" aria-disabled="true" href="javascript:void(0)">
    <slot />
  </a>
  <a v-else-if="typeof to === 'string' && externalLinkRegex.test(to)" :href="to" v-bind="$attrs">
    <slot />
  </a>
  <RouterLink v-else v-bind="{ ...$props, ...$attrs }">
    <slot />
  </RouterLink>
</template>

<style scoped lang="scss">

</style>
