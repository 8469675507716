<script setup lang="ts" generic="TSchema extends ZodType">
import { useForm } from 'vee-validate'
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import ModalFooter from '@/components/modal/modal-footer.vue'
import { z, ZodType } from 'zod'
import { computed } from 'vue'
import { toTypedSchema } from '@vee-validate/zod'
import ButtonSecondary from '@/components/button-secondary.vue'
import ButtonPrimary from '@/components/button-primary.vue'
import { faFloppyDisk, faTimes } from '@fortawesome/pro-regular-svg-icons'

const props = defineProps<{
  title?: string
  schema?: TSchema
}>()

const emit = defineEmits<{
  cancel: [],
  submit: [values: z.infer<TSchema>],
}>()

const validationSchema = computed(() => props.schema ? toTypedSchema(props.schema) : undefined)
const { handleSubmit } = useForm({ validationSchema })

const saveHandler = handleSubmit(
  (values) => {
    emit('submit', values)
  }
)
</script>

<template>
  <ModalContainer>
    <slot name="title">
      <ModalTitle>{{ title }}</ModalTitle>
    </slot>

    <ModalContent>
      <slot />
    </ModalContent>

    <ModalFooter class="text-end">
      <ButtonSecondary @click="$emit('cancel')" :icon="faTimes">Cancel</ButtonSecondary>
      <ButtonPrimary @click="saveHandler" :icon="faFloppyDisk" class="ms-3">Save</ButtonPrimary>
    </ModalFooter>
  </ModalContainer>
</template>

<style scoped lang="scss">

</style>
