<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  span?: number|boolean
  start?: number
}>()

const gridItemStyles = computed(
  () => {
    const styles: Record<string, string> = { }

    // Span the entire row.
    if (typeof props.span === 'boolean' && props.span) {
      styles['--grid-column-start'] = '1'
      styles['--grid-column-end'] = '-1'

      return styles
    }

    if (typeof props.span === 'number') {
      styles['--grid-column-end'] = `span ${props.span}`
    }

    if (typeof props.start === 'number') {
      styles['--grid-column-start'] = `${props.start}`
    }

    return styles
  }
)
</script>

<template>
  <div :class="$style['grid-item']" :style="gridItemStyles">
    <slot />
  </div>
</template>

<style module lang="scss">
.grid-item {
  grid-column-start: var(--grid-column-start);
  grid-column-end: var(--grid-column-end);
}
</style>