<script setup lang="ts">
import { ref, watch } from 'vue';
import { faPlus, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import Card from "@/components/Card/card-container.vue";
import CardContent from "@/components/Card/card-content.vue";
import CardHeader from "@/components/Card/card-header.vue";
import CardHeaderItem from '@/components/Card/card-header-item.vue';
import { type Contact} from "@/features/contacts/schemas";
import ConfirmationModal from '@/components/ui/modals/confirmation-modal.vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Button from "@/components/button-secondary.vue";
import InputCheckbox from "@/components/input-checkbox.vue";
import DropDownContext from "@/components/dropdown-context.vue";
import ContentLoader from "@/components/content-loader.vue";
import NewContactModal from "@/views/assetSetup/modals/new-contact-modal.vue";
import ExistingContactModal from "@/views/assetSetup/modals/existing-contact-modal.vue";
import { useDeleteContacts, useContacts } from '@/composables/contacts/contacts';
import { CONTACT_TYPES } from '@/features/constants';

const props = defineProps<{ assetCode: number }>();

const emit = defineEmits<{
    (event: 'update', payload: Contact[]): void
}>();

const { data: contactData, isLoading: contactLoading} = useContacts(props.assetCode,CONTACT_TYPES.invoice);
const contacts = ref<Contact[]>([]);
const deletedContact = ref<Contact>();
const showModalDelete = ref(false);
const showModalAddContact = ref(false);
const showModalExistingContact = ref(false);
const { mutateAsync } = useDeleteContacts()
watch(
    contactData,
    async (newContacts) => {
        if (newContacts === undefined) {
            return
        }
        contacts.value = newContacts.map(contact => ({ ...contact })).filter(x=>{return x.linked});
    }, { immediate: true });
const handleContactDelete = (newContact: Contact) => {
    showModalDelete.value = true;
    deletedContact.value = newContact;
    deletedContact.value.linked = false;
};
function closeModalDelete() {
    showModalDelete.value = false;
}
const deleteContactConfirmed = async (id: number) => {
    await mutateAsync(id)
    showModalDelete.value = false;
}

const handleNewContactClose = () => {
    showModalAddContact.value = false;
}
const handleNewContactShow = () => {
    showModalAddContact.value = true;
}
const handleExistintingContactClose = () => {
    showModalExistingContact.value = false;
}

watch(contacts,
    (newContacts) => {
        emit('update', newContacts)
    },{deep:true,immediate:true}
)
</script>
<template>
    <ConfirmationModal :value="showModalDelete" @confirmed="deleteContactConfirmed(deletedContact?.id!)"
        @rejected="closeModalDelete"
        :title="`CONFIRM YOU’D LIKE TO DELETE ${deletedContact?.firstname} ${deletedContact?.surname}`"
        confirm-text="Proceed" reject-text="Cancel">
        <div class="d-flex gap-4">
            <FontAwesomeIcon :icon="faTriangleExclamation" class="fa-4x color-temp-yellow" />
            <span>The contact will be removed but still be part of your organisation contacts
                list.
            </span>
        </div>
    </ConfirmationModal>
    <NewContactModal
      :asset-code="assetCode"
      v-if="showModalAddContact"
      @closed="handleNewContactClose"
    />
    <ExistingContactModal
      :asset-code="assetCode"
      v-if="showModalExistingContact"
      @close="handleExistintingContactClose"
    />
    <Card class="invoice-contact">
        <CardHeader>
            <CardHeaderItem>Invoice Contact</CardHeaderItem>
        </CardHeader>
        <CardContent>
            <ContentLoader :loading="contactLoading" variant="overlay">
                <div class="button-wrapper">
                    <Button :icon="faPlus" @click="handleNewContactShow">New Contact</Button>
                    <Button :icon="faPlus" @click="showModalExistingContact = true">Add Existing</Button>
                </div>
                <table class="table table-no-bottom-border">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Contact No.</th>
                            <th scope="col">Email</th>
                            <th scope="col">Primary</th>
                            <th scope="col">CC</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(contact, index) in contacts" :key="index"
                            :class="{ 'last-row': index === contacts?.length - 1 }">
                            <th scope="row">{{ contact.firstname }}</th>
                            <td>{{ contact.phoneNumbers[0]?.phoneNumber }}</td>
                            <td>{{ contact.emailAddresses[0]?.emailAddress }}</td>
                            <td>
                                <InputCheckbox v-model:checked="contact.primaryContact" :disabled="contact.secondaryContact"/>
                            </td>
                            <td>
                                <InputCheckbox v-model:checked="contact.secondaryContact" :disabled="contact.primaryContact"/>
                            </td>
                            <td>
                                <DropDownContext>
                                    <template #default>
                                        <div class="padding-dropdown" @click="handleContactDelete(contact)">
                                            Delete
                                        </div>
                                    </template>
                                </DropDownContext>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ContentLoader>
        </CardContent>
    </Card>
</template>
<style scoped lang="scss">
.invoice-contact {
    width: 561px;
    min-height: 198px;
    max-height: 380px;
    overflow-y: auto;
}

.padding-dropdown {
    margin-left: 10px;
}

.button-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
}
.button-wrapper button {
  width: 110px;
  height: 31px;
}
</style>
