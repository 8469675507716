import { format } from "date-fns/format";
import { formatISO } from "date-fns/formatISO";

const mainCurrencySymbols = { 'ZAR': 'R' }
const fractionalCurrencySymbols = { 'ZAR': 'c' }

const defaultNumberFormatter = new Intl.NumberFormat('en-US',
  {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 5
  }
)

export function formatNumber(value: number){
  return defaultNumberFormatter.format(value).replace(/,/g, ' ')
}


export function formatDateAsDay(date: Date|number|string, fallback: string = '') {
  return date ? format(date, 'dd MMM yyyy') : fallback
}

export enum DateFormats {
  YearMonthDay = 'yyyy-LL-dd',
  HourMinute = 'HH:mm',
  Date = 'dd MMM yyyy',
  DayMonthYear = 'dd MMM yyyy',
  DayMonthYearFull = 'dd MMMM yyyy',
  UserFriendly = "dd MMM yyyy '@' HH:mm"
}

export const dateToIso = (date: Date|number|string) => formatISO(date)


export function dateToYMD(date: Date|number|string, fallback: string = '') {
  return date ? format(date, 'yyyy-LL-dd') : fallback
}

export function dateToDay(date: Date|number|string, fallback: string = ''){
  return date ? format(date, 'dd') : fallback
}

export function getDayOrdinalSuffix(date: Date){
  const day = date.getDate()

  if (day > 3 && day < 21) return `${day}th`; // covers 11th-19th
  switch (day % 10) {
    case 1: return `${day}st`;
    case 2: return `${day}nd`;
    case 3: return `${day}rd`;
    default: return `${day}th`;
  }
}

export function formatToMainCurrency(value: number, config: Partial<{ currency: 'ZAR', showSymbol: boolean, maxPrecision: number }> = { }) {
  const { currency = 'ZAR', showSymbol = true, maxPrecision = 2 } = config

  const formattedValue = new Intl.NumberFormat(
    "en-US",
    {
      style: 'decimal',
      maximumFractionDigits: maxPrecision,
      minimumFractionDigits: 2,
    }
  ).format(value).replace(/,/g, ' ')

  if (!showSymbol) {
    return formattedValue
  }

  return `${mainCurrencySymbols[currency]} ${formattedValue}`
}

export function formatToFractionalCurrency(value: number, config: Partial<{ currency: 'ZAR', showSymbol: boolean, maxPrecision: number }> = { }) {
  const { currency = 'ZAR', showSymbol = false, maxPrecision = 5 } = config

  const formattedValue = new Intl.NumberFormat(
    "en-US",
    {
      style: 'decimal',
      maximumFractionDigits: maxPrecision,
      minimumFractionDigits: 0,
    }
  ).format(value).replace(/,/g, ' ')

  if (!showSymbol) {
    return formattedValue
  }

  return `${formattedValue}${fractionalCurrencySymbols[currency]}`
}
