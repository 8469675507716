import { z } from "zod";
export type Contact = z.infer<typeof ContactsSchema>
export const ContactsSchema = z.object({
    id: z.number().default(0),
    contactTypeId: z.number().default(11),
    firstname: z.string().default(''),
    surname: z.string().nullable().default(''),
    street: z.string().nullable().default(''),
    suburb: z.string().nullable().default(''),
    city: z.string().nullable().default(''),
    countryId: z.number().nullable().default(0),
    primaryContact: z.boolean().default(false),
    secondaryContact: z.boolean().default(false),
    linked: z.boolean().default(true),
    emailAddresses: z.array(z.object({
        id: z.number().int().default(0),
        contactId: z.number().int().default(0),
        emailAddress: z.string().default(''),
    })).default([{ id: 0, contactId: 0, emailAddress: '' }]),
    phoneNumbers: z.array(z.object({
        id: z.number().int().default(0),
        contactId: z.number().int().default(0),
        phoneNumber: z.string().default(''),
    })).default([{ id: 0, contactId: 0, phoneNumber: '' }])
})
