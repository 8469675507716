import type { RouteLocationNormalizedLoaded, RouteLocationRaw } from 'vue-router'
import router from '@/router'

export function queryAsString(name: string, route: RouteLocationNormalizedLoaded): string | undefined
export function queryAsString<F extends string|undefined>(name: string, route: RouteLocationNormalizedLoaded, fallback: F): string|F
export function queryAsString(name: string, route: RouteLocationNormalizedLoaded, fallback?: string): string|undefined {
  if (!(name in route.query)) {
    return fallback
  }

  const value = route.query[name]
  const singleValue = Array.isArray(value) ? value[0] : value

  return singleValue === null ? fallback : singleValue
}


export function queryAsNumber(name: string, route: RouteLocationNormalizedLoaded): number|undefined;
export function queryAsNumber<F extends number|undefined>(name: string, route: RouteLocationNormalizedLoaded, fallback: F): number|F;
export function queryAsNumber(name: string, route: RouteLocationNormalizedLoaded, fallback?: number): number|undefined {
  if (!(name in route.query)) {
    return fallback
  }

  const value = route.query[name]
  const singleValue = Array.isArray(value) ? value[0] : value

  if (singleValue === null) {
    return fallback
  }

  const parsedValue = parseFloat(singleValue)

  return !isNaN(parsedValue) ? parsedValue : fallback
}


export function makeCoreUrl(path: string | string[]): string {
  let built: string

  if (typeof path === 'string') {
    built = path.replace(/^\/+/, '')
  } else {
    built = path.map(v => encodeURIComponent(v)).join('/')
  }

  return `${import.meta.env.VITE_CORE_URL}/${built}`
}


export function navigateTo(to: RouteLocationRaw) {
  return router.push(typeof to === 'string' ? { name: to } : to)
}

export function navigateToCoreUrl(...args: Parameters<typeof makeCoreUrl>) {
  window.location.href = makeCoreUrl(...args);
}