<template>
  <h1 :class="$style.title">
    <slot />

    <span v-if="$slots.subtitle" :class="$style.subtitle" class="ms-2">
      <slot name="subtitle" />
    </span>
  </h1>
</template>

<style module lang="scss">
.title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle {
  //text-transform: none;
  color: #b4b4b4;
  font-size: 14px;
}
</style>
