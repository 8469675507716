import { buildApiUrl, sendGetRequest, Type } from '@/features/api'
import { assertSchemaAndReturn } from '@/helpers/assert-schema'
import { AssetDetailsSchema } from '@/features/assets/schemas'

export const getAssetDetails = (assetCode: number) =>
  sendGetRequest(
    buildApiUrl(Type.AssetManagement, `v2/assets/${assetCode}`),
  ).then(
    ({ data }) => assertSchemaAndReturn(data, AssetDetailsSchema)
  )
