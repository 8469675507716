<script setup lang="ts">
import DropdownMenu from "@/components/dropdown-menu.vue";
import { ref, useAttrs } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEllipsisVertical as iconRegular } from "@fortawesome/pro-regular-svg-icons";

const attrs = useAttrs();
const isOpen = ref<boolean>(false);
</script>

<template>
  <DropdownMenu v-bind="attrs" @open="isOpen = true" @close="isOpen = false">
    <button :class="[$style.trigger, { [$style.open]: isOpen }]">
      <FontAwesomeIcon :icon="iconRegular" />
    </button>

    <template #items>
      <slot />
    </template>
  </DropdownMenu>
</template>

<style module lang="scss">
.trigger {
  width: 24px;
  height: 24px;
  padding: 4px 3px;
  border-radius: 100%;
  text-align: center;
  border-style: none;
  white-space: nowrap;
  background: transparent;

  &.open,
  :focus {
    background: #f6f6f6;
  }
}
</style>
