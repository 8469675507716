import { useQuery } from "@tanstack/vue-query";
import { getUserFlags } from "@/features/users/flags";
import { AccessArea, AccessRight } from "@/features/users/constants";
import { getUserDetails, hasAbility, hasPermission } from "@/features/users/users";

export { AccessArea, AccessRight }

export default function useAuthenticatedUserDetails() {
  const query = useQuery({
    queryKey: ['users:details', 'me'],
    staleTime: 60_000,
    queryFn: async () => {
      const userDetailsPromise = getUserDetails('me')
      const userFlagsPromise = getUserFlags('me')

      return {
        ...(await userDetailsPromise),
        flags: await userFlagsPromise
      }
    }
  })

  return {
    ...query,
    hasPermission: (area: AccessArea, ...rights: AccessRight[]) => {
      const user = query.data.value

      if (user === undefined) {
        return false
      }

      return hasPermission(user.permissions, area, ...rights)
    },
    hasAbility: (ability: Parameters<typeof hasAbility>[1]) => {
      const user = query.data.value

      if (user === undefined) {
        return undefined
      }

      return hasAbility(user.permissions, ability)
    }
  }
}
