<script setup lang="ts">
import 'bootstrap/js/dist/dropdown'
import { onMounted, ref } from "vue";

withDefaults(
  defineProps<{ align?: 'left' | 'right' }>(),
  { align: 'right' }
)

const emit = defineEmits<{ open: [], close: [] }>()
const $root = ref<HTMLDivElement>()

onMounted(
  () => {
    $root.value?.addEventListener('show.bs.dropdown', () => emit('open'))
    $root.value?.addEventListener('hide.bs.dropdown', () => emit('close'))
  }
)
</script>

<template>
  <div :class="$style['container']" class="dropdown" ref="$root">
    <div data-bs-toggle="dropdown">
      <slot />
    </div>
    <ul class="dropdown-menu" :class="{
      'dropdown-menu-end': align !== 'left',
      'dropdown-menu-start': align === 'left',
    }">
      <slot name="items" />
    </ul>
  </div>
</template>

<style module lang="scss">
.container {
  & > div:first-child {
    display: inline-block;
  }

  :global(.dropdown-menu) {
    max-height: 500px;
    overflow-y: auto;

    li {
      cursor: pointer;
    }
  }
}
</style>
