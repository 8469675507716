<template>
  <span class="badge bg-secondary" :class="$style.secondary"><slot /></span>
</template>

<style module lang="scss">
@import "@/assets/variables";

.secondary {
  --#{$prefix}badge-font-weight: normal;
  --#{$prefix}badge-font-size: 1em;
  --#{$prefix}badge-color: #{$white};
}
</style>