<template>
  <div class="tooltip-container">
    <span class="tooltip-icon" @mouseenter="openTooltip" @mouseleave="closeTooltip">
      <slot name="icon"></slot>
    </span>
    <div
      v-if="showTooltip"
      @mouseenter="openTooltip"
      @mouseleave="closeTooltip"
      class="tooltip-text"
    >
      <div class="triangle" />
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const showTooltip = ref(false);
function openTooltip() {
  showTooltip.value = true;
}
function closeTooltip() {
  showTooltip.value = false;
}
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  /* width: 15px; */
}

.tooltip-icon {
  cursor: help;
}

.triangle {
  z-index: 999999999999999999999999;
  position: absolute;
  top: -5px;
  left: calc(50% - 5.38px);
  background: rgba(0, 0, 0, 0.8);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  width: 11.36px;
  height: 5.68px;
}

.tooltip-text {
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translate(50%, 0);
  z-index: 99999999999999999999;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: normal;
  min-width: 101px;
  max-width: 300px;
  width: max-content;
  text-align: center;
}
</style>
