<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted } from "vue";

export interface ModalBaseProps {
  blur?: boolean
  closeable?: boolean
  width?: number
}

const props = withDefaults(
  defineProps<ModalBaseProps>(),
  { blur: true, closeable: true }
)

const emit = defineEmits<{ 'close': [] }>()

const modalStyles = computed(
  () => {
    const styles: Record<string, string> = { }

    if (props.width !== undefined) {
      styles['--width'] = `${props.width}px`
    }

    return styles
  }
)

const listenForEscapeKey = (e: KeyboardEvent) => {
  if (props.closeable && e.key.toLowerCase() === 'escape') {
    emit('close')
  }
}

onMounted(
  () => {
    document.addEventListener('keydown', listenForEscapeKey)
  }
)

onBeforeUnmount(
  () => {
    document.removeEventListener('keydown', listenForEscapeKey)
  }
)
</script>

<template>
  <Teleport to="body">
    <div :class="[$style['modal-container'], { [$style['blur']]: blur }]">
      <div :class="$style['modal']" :style="modalStyles">
        <slot :close="() => { $emit('close') }" />
      </div>
    </div>
  </Teleport>
</template>

<style module lang="scss">
@import "@/assets/variables";

.modal-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;

  &.blur {
    backdrop-filter: blur(1px);
  }
}

.modal {
  background: #fff;
  outline: none;
  border: 1px solid #ddd;
  margin-left: auto;
  margin-right: auto;
  width: var(--width, 500px);
  box-shadow: 0 5px 3px #00000040;
  padding: 25px 25px 30px 25px;
  border-radius: 4px;
  color: var(--#{$prefix}body-color);
  margin-top: 75px;
  overflow: visible;

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  &.blur::backdrop {
    backdrop-filter: blur(1px);
  }
}
</style>
