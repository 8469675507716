export const ALLOWED_PAGES = {
    settings: 'settings',
    manageInvoice: 'manageInvoice',
    performance: 'performance',
} as const;

export type PageList = typeof ALLOWED_PAGES[keyof typeof ALLOWED_PAGES];
export const CONTACT_TYPES ={
    invoice :11
}
