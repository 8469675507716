<template>
  <span class="badge" :class="$style.cyan"><slot /></span>
</template>

<style module lang="scss">
@import "@/assets/_variables.scss";

.cyan {
  --#{$prefix}badge-font-weight: normal;
  --#{$prefix}badge-font-size: 1em;
  color: white;
  background-color: #41c8dB;
}
</style>
