// Import styling.
import './assets/main.scss'

// Bootstrap JS functionality is imported in the components that require it.
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { QueryCache, QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import tooltipDirective from '@/directives/tooltip'
import { createLogger } from "@/helpers/logging";

const logger = createLogger('global')
const app = createApp(App)

// Register global directives.
app.directive('tooltip', tooltipDirective)

app.config.errorHandler = (err, instance, info) => {
  // TODO Configure Grafana Faro integration.
  logger.critical(
    'Encountered unhandled error.',
    { error: err, info, instance }
  )
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error, query) {
      const _logger = query.meta?.logger || logger

      _logger.error('Encountered query error', { error, queryHash: query.queryHash })
    }
  }),
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
})


app.use(createPinia())
app.use(router)
app.use(VueQueryPlugin, { queryClient, enableDevtoolsV6Plugin: true })

app.mount('#app')
