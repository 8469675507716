import {z, ZodError} from "zod"

export function assertSchema<TData, TSchema extends z.Schema>(data: TData, schema: TSchema): asserts data is z.infer<TSchema> {
  const result = schema.safeParse(data)

  if (!result.success) {
    throw new SchemaAssertionError(result.error)
  }
}

export function assertSchemaAndReturn<TData, TSchema extends z.Schema>(data: TData, schema: TSchema): z.infer<TSchema> {
  const result = schema.safeParse(data)

  if (!result.success) {
    throw new SchemaAssertionError(result.error)
  }

  return result.data as z.infer<TSchema>
}

export class SchemaAssertionError extends Error {
  readonly errors: Partial<Record<string, string>> = { }
  readonly previous: ZodError

  constructor(previous: ZodError) {
    super('Schema assertion failed')

    this.previous = previous

    const { _errors } = previous.format()

    for (const field in _errors) {
      this.errors[field] = _errors[field][0]
    }
  }

  toJSON() {
    return this.previous.errors
  }
}
