import { computed, ref } from 'vue'

export function useModalState(options: { initialValue?: boolean } = { }) {
  const { initialValue = false } = options

  const isVisible = ref(initialValue)

  return {
    isOpen: computed(() => isVisible.value),
    isClosed: computed(() => !isVisible.value),
    open: () => isVisible.value = true,
    close: () => isVisible.value = false
  }
}
