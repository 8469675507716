import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { getAvailableTariffs, getTariffsForAsset, saveTariffsForAsset } from "@/features/billing/tariffs";
import { type MaybeRefOrGetter, toValue } from "vue";
import { createLogger } from "@/helpers/logging";

export function useAvailableTariffs() {
  return useQuery({
    queryKey: ['assets:settings:lookup'],
    meta: {
      logger: createLogger(`composables/tariffs/useAvailableTariffs`)
    },
    queryFn: () => getAvailableTariffs()
  })
}


export function useTariffsForAsset(assetCode: MaybeRefOrGetter<number>) {
  return useQuery({
    queryKey: ['assets:tariffs', assetCode],
    meta: {
      logger: createLogger(`composables/tariffs/useTariffsForAsset`)
    },
    queryFn: () => getTariffsForAsset(toValue(assetCode))
  })
}


export function useSaveTariffs(assetCode: MaybeRefOrGetter<number>) {
  type MutationArgs = Parameters<typeof saveTariffsForAsset>[1]
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variables: MutationArgs) => saveTariffsForAsset(toValue(assetCode), variables),
    async onSuccess() {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['assets:settings', assetCode] }),
        queryClient.invalidateQueries({ queryKey: ['assets:tariffs', assetCode] }),
      ])
    },
  })
}
