import useAuthenticatedUserDetails from "@/composables/users/authenticated-user-details";
import { unref } from "vue";
import routeNames from "@/router/names";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { useRouter } from "vue-router";

export default async function requireAuth (to: RouteLocationNormalized): Promise<boolean|void|RouteLocationRaw> {
  const router = useRouter()

  // If nothing is matched, then assume no auth is required.
  if (to.matched.length < 1) {
    return false
  }

  // For now, whether auth is required or not is determined by the parent route.
  const { requiresAuth } = to.matched[0].meta

  // No auth is required, so do nothing.
  if (requiresAuth === false) {
    return true
  }

  // auth is required. Let's check if we're authenticated.
  const { data: user, suspense } = useAuthenticatedUserDetails()

  // Ensure the user has loaded.
  try {
    await suspense()
  } catch (e: unknown) {
    // void
  }

  // If there is no user, then redirect to the login page.
  if (unref(user) === undefined) {
    return {
      name: routeNames.login,
      query: {
        r: router.resolve(to).href
      }
    }
  }
}
