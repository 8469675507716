<template>
  <div :class="$style['footer']"><slot /></div>
</template>

<style module lang="scss">
.footer {
  margin-top: 18px;

  :global(.btn) {
    min-width: 100px;
  }
}
</style>