import { useQuery } from '@tanstack/vue-query'
import getOrganisationList from '@/features/organisations/get-organisation-list'

export default function useOrganisationList() {
  return useQuery({
    queryKey: ['organisations:list'],
    queryFn: () => getOrganisationList()
  })
}

