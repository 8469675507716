<script setup lang="ts">
import Card from "@/components/Card/card-container.vue";
import CardContainer from "@/components/Card/card-container.vue";
import CardHeader from "@/components/Card/card-header.vue";
import CardHeaderItem from '@/components/Card/card-header-item.vue';
import CardContent from "@/components/Card/card-content.vue";
import GridContainer from "@/components/grid-container.vue";
import GridItem from "@/components/grid-item.vue";
import InputLabel from "@/components/input-label.vue";
import InputCheckbox from "@/components/input-checkbox.vue";
import { computed, ref, watch } from "vue";
import InputSelect from "@/components/input-select.vue";
import InputDate from "@/components/input-date.vue";
import InputNumber from "@/components/input-number.vue";
import InputToggleGroup from "@/components/input-toggle-group.vue";
import { useAvailableTariffs, useTariffsForAsset } from "@/composables/billing/tariffs";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import { ExportPowerEscalationType } from "@/features/billing/constants";
import InputCurrency from "@/components/input-currency.vue";
import { Field } from "vee-validate";

const props = defineProps<{ assetCode: number }>();
const emit = defineEmits<{ 'update:isExportingPower': [boolean] }>()

const { data: tariffs, isLoading: tariffsAreLoading, isError: isTariffsError } = useAvailableTariffs()
const { data: assetTariffs, isLoading: assetTariffsAreLoading, isError: isAssetTariffsError } = useTariffsForAsset(props.assetCode)

const isLoading = computed(() => tariffsAreLoading.value || assetTariffsAreLoading.value)

const escalationTypes = [
  { value: ExportPowerEscalationType.Currency, text: 'R' },
  { value: ExportPowerEscalationType.Percentage, text: '%' }
]

const isExportingPower = ref<boolean>()
const selectedEscalationType = ref(ExportPowerEscalationType.Currency)
const selectedTariffStructure = ref<number>()

watch(isExportingPower, isExporting => emit('update:isExportingPower', isExporting!))

watch(
  assetTariffs,
  (newValue) => {
    if (newValue === undefined) {
      return
    }

    if (selectedTariffStructure.value === undefined) {
      selectedTariffStructure.value = newValue.tariffStructure
    }

    if (isExportingPower.value === undefined) {
      isExportingPower.value = newValue.exportPower
    }

    if (newValue.escalationType !== null && newValue.escalationType !== undefined) {
      selectedEscalationType.value = newValue.escalationType
    }
  }, { immediate: true }
)

const tariffTypes = computed(
  () => {
    const id = selectedTariffStructure.value

    if (id === undefined) {
      return []
    }

    return tariffs.value?.find(x => x.id === id)?.types || []
  }
)

function getTariffRate(rateId: number, kind: 'export' | 'pv') {
  const found = assetTariffs.value?.billingRates.find(x => x.tariffTypeId === rateId)

  return kind === 'export'
    ? found?.exportPowerInCents
    : found?.rateInCents
}

</script>

<template>
  <Card :class="$style['pv-profile']">
    <CardContainer>
      <CardHeader>
        <CardHeaderItem>Tariff Profile</CardHeaderItem>
      </CardHeader>
      <CardContent>
        <ContentLoader :loading="isLoading" variant="overlay">
          <HeroSplashError v-if="isAssetTariffsError || isTariffsError" />

          <GridContainer v-else :columns="[118, '1fr']" align="center">
            <GridItem>
              <InputLabel class="card-input-title">Export Power?</InputLabel>
            </GridItem>
            <GridItem>
              <InputCheckbox v-model:checked="isExportingPower" />
            </GridItem>

            <template v-if="isExportingPower">
              <GridItem>
                <InputLabel class="card-input-title">Export Escalation Date</InputLabel>
              </GridItem>
              <GridItem>
                <InputDate name="tariffProfile.escalationDate" error-variant="tooltip" required :value="assetTariffs?.escalationDate" />
              </GridItem>

              <GridItem>
                <InputLabel class="card-input-title">Export Escalation</InputLabel>
              </GridItem>
              <GridItem>
                <GridContainer :columns="['60%', '1fr']" align="center">
                  <GridItem>
                    <Field as="template" name="tariffProfile.escalationType" :model-value="selectedEscalationType" />
                    <InputNumber name="tariffProfile.escalationValue" error-variant="tooltip" required :value="assetTariffs?.escalationValue">
                      <template v-if="selectedEscalationType === ExportPowerEscalationType.Currency"
                        #prefix>R</template>
                      <template v-if="selectedEscalationType === ExportPowerEscalationType.Percentage"
                        #suffix>%</template>
                    </InputNumber>
                  </GridItem>
                  <GridItem>
                    <InputToggleGroup :options="escalationTypes" v-model:selected="selectedEscalationType" />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </template>

            <GridItem>
              <InputLabel class="card-input-title">Tariff Structure</InputLabel>
            </GridItem>
            <GridItem>
              <InputSelect
                name="tariffProfile.tariffStructureId"
                required
                :items="tariffs || []"
                :placeholder="tariffsAreLoading ? 'Loading...' : undefined"
                v-model:selected="selectedTariffStructure"
                error-variant="tooltip"
                item-value="id"
                item-text="name" />
            </GridItem>
          </GridContainer>
        </ContentLoader>

        <table class="table table-sm mt-4" v-if="selectedTariffStructure">
          <thead>
            <tr>
              <th>Description</th>
              <th>PV Rate</th>
              <th v-if="isExportingPower">Export Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(type) in tariffTypes" :key="type.id">
              <td>{{ type.name }}</td>
              <td>
                <InputCurrency
                  required
                  style="width: 68px"
                  :name="`tariffProfile.rates[${type.id}].pvRate`"
                  :value="getTariffRate(type.id, 'pv')"
                  error-variant="tooltip" />
              </td>
              <td v-if="isExportingPower">
                <InputCurrency
                  required
                  style="width: 68px"
                  :name="`tariffProfile.rates[${type.id}].exportRate`"
                  :value="getTariffRate(type.id, 'export')"
                  error-variant="tooltip" />
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </CardContainer>
  </Card>
</template>

<style module lang="scss">
.pv-profile {
  width: 407px;

  .card-base-grid {
    display: grid;
    align-items: baseline;
    row-gap: 10px;
    column-gap: 20px;
    color: #6d6e71;
  }
}
</style>
