import type { Projects } from "@/types";
import makeApiClient, { Type } from "@/features/api";

type Argument = {
  projectId: number
  type: Projects.TransferType
  toUserId: string
}

export default async function transferProject({ projectId, type, toUserId }: Argument) {
  const { sendPostRequest } = makeApiClient(Type.Core)

  const body = { type, toUserId }

  await sendPostRequest(`projects/${projectId}/transfers`, body)
}