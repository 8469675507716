<template>
  <span class="badge" :class="$style.red"><slot /></span>
</template>

<style module lang="scss">
@import "@/assets/_variables.scss";

.red {
  --#{$prefix}badge-font-weight: normal;
  --#{$prefix}badge-font-size: 1em;
  background-color: $danger;
  color: white;
}
</style>