<script setup lang="ts">
import ModalBase from "@/components/modal/modal-container.vue";
import ModalTitle from "@/components/modal/modal-title.vue";
import ModalContent from "@/components/modal/modal-content.vue";
import ModalFooter from "@/components/modal/modal-footer.vue";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import ButtonSecondary from "@/components/button-secondary.vue";
import ButtonPrimary from "@/components/button-primary.vue";
import { computed } from "vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import useProjectTransfers from "@/composables/projects/project-transfers";
import ContentLoader from "@/components/content-loader.vue";
import useNotifications from "@/composables/notifications";
import type { PaginatedProject } from "@/features/projects/get-paginated-project-list";
import useUserDetails from "@/composables/users/user-details";

const $emit = defineEmits<{ close: [] }>()
const $props = defineProps<{
  transfer: PaginatedProject['transfers'][number],
  projectId: number,
  projectName: string
}>()

const { addError, addSuccess } = useNotifications()
const {
  data: user,
  isLoading: isLoadingUser,
  isError: isErrorUser
} = useUserDetails($props.transfer.fromUserId)

const {
  rejectTransfer: {
    mutateAsync: rejectTransfer,
    isError: rejectTransferHasError,
    isPending: rejectTransferIsPending
  },
  acceptTransfer: {
    mutateAsync: acceptTransfer,
    isError: acceptTransferHasError,
    isPending: acceptTransferIsPending
  }
} = useProjectTransfers({
  type: $props.transfer.type,
  projectId: $props.projectId
})

const actioningType = computed<'accept' | 'reject' | undefined>(
  () => {
    if (acceptTransferIsPending.value) {
      return 'accept'
    } else if (rejectTransferIsPending.value) {
      return 'reject'
    } else {
      return undefined
    }
  }
)

const isError = computed(
  () => isErrorUser.value || acceptTransferHasError.value || rejectTransferHasError.value
)

async function doRejectTransfer() {
  try {
    await rejectTransfer()

    addSuccess({
      title: `${$props.transfer.type === 'assignee' ? 'Assignment' : 'Ownership'} transfer rejected.`,
    })

  } catch (e: unknown) {
    addError({
      title: 'Unable to reject transfer',
      message: 'Please try again.',
    })
  }

  $emit('close')
}

async function doAcceptTransfer() {
  try {
    await acceptTransfer()

    addSuccess({
      title: `${$props.transfer.type === 'assignee' ? 'Assignment' : 'Ownership'} transfer accepted.`,
      message: 'You are now able to manage this project.',
    })
  } catch (e: unknown) {
    addError({
      title: 'Unable to accept transfer',
      message: 'Please try again.',
    })
  }

  $emit('close')
}
</script>

<template>
  <ModalBase @close="$emit('close')" :width="395">
    <ModalTitle>{{ transfer.type === 'owner' ? 'Transfer request' : 'Assignment request' }}</ModalTitle>

    <ModalContent v-if="isError">
      <HeroSplashError />
    </ModalContent>

    <ModalContent v-else>
      <ContentLoader :loading="isLoadingUser">
        <template v-if="transfer.type === 'owner'">
          <p><b>{{ user!.name }}</b> would like to transfer <b>{{ projectName }} ({{ projectId }})</b> to you.</p>
          <p>By accepting, you'll become the owner & assignee of this project. If declined, ownership will remain with the current owner.</p>
        </template>
        <template v-else-if="transfer.type === 'assignee'">
          <p><b>{{ user!.name }}</b> would like to assign <b>{{ projectName }} ({{ projectId }})</b> to you.</p>
          <p>If not accepted, the project will remain assigned to the current assignee.</p>
        </template>
      </ContentLoader>
    </ModalContent>

    <ModalFooter class="text-end">
      <ButtonSecondary @click="doRejectTransfer" :preset="actioningType === 'reject' ? 'loading' : undefined" :disabled="!!actioningType" :icon="faTimes">Reject</ButtonSecondary>
      <ButtonPrimary class="ms-2" @click="doAcceptTransfer" :disabled="!!actioningType" :preset="actioningType === 'accept' ? 'loading' : undefined" :icon="faCheck">Accept</ButtonPrimary>
    </ModalFooter>
  </ModalBase>
</template>

<style module lang="scss">

</style>