<script setup lang="ts">

</script>

<template>
<h1>Terms of use</h1>
</template>

<style scoped>

</style>