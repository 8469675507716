import type { RouteLocationNormalized } from "vue-router";

export default function setPageTitle(to: RouteLocationNormalized) {
  const titles = ['Unifii'].concat(
    to.matched
      .map(route => route.meta.pageTitle)
      .filter((title): title is string => title !== undefined)
  )

  document.title = titles.join(' | ')
}
