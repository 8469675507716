<template>
  <span class="badge" :class="$style.yellow"><slot /></span>
</template>

<style module lang="scss">
@import "@/assets/_variables.scss";

.yellow {
  --#{$prefix}badge-font-weight: normal;
  --#{$prefix}badge-font-size: 1em;
  background-color: #{$yellow};
  color: #{$body-color};
}
</style>