import type { Slot } from 'vue'
import { Comment, Text } from 'vue'

export function slotHasContent(slot: Slot | undefined) {
  if (!slot) {
    return true
  }
}

/**
 * See https://github.com/vuejs/core/issues/4733#issuecomment-1024816095 for the source of this.
 */
export function isSlotFilled(slot: Slot | undefined): boolean {
  if (!slot) {
    return false
  }

  return slot().some((vNode) => {
    if (vNode.type === Comment) {
      return false
    }

    if (Array.isArray(vNode.children) && !vNode.children.length) {
      return false
    }

    return (
      vNode.type !== Text || (typeof vNode.children === 'string' && vNode.children.trim() !== '')
    )
  })
}

export function isSlotEmpty(slot: Slot | undefined): boolean {
  return !isSlotFilled(slot)
}