<script setup lang="ts">
import { useSlots } from "vue";
import ModalContainer from "@/components/modal/modal-container.vue"
import ModalTitle from "@/components/modal/modal-title.vue";
import ModalContent from "@/components/modal/modal-content.vue";
import ModalFooter from "@/components/modal/modal-footer.vue";
import Button from "@/components/button-secondary.vue";
import ButtonPrimary from "@/components/button-primary.vue";
import { faArrowRight, faXmark } from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { IconName } from "@fortawesome/fontawesome-svg-core";

const props = defineProps<{
  value: boolean;
  title?: string;
  icon?: IconName;
  iconStyle?: string;
  confirmText?: string;
  rejectText?: string;
}>();

const emit = defineEmits<{
  (e: "confirmed"): void;
  (e: "rejected"): void;
}>();

const handleAccept = () => {
  emit("confirmed");
};

const handleReject = () => {
  emit("rejected");
};

const slots = useSlots();
</script>

<template>
  <ModalContainer v-if="value">
    <ModalTitle>{{ slots.title || props.title }}</ModalTitle>
    <ModalContent>
      <div class="native-modal-confirmation--content-wrapper">
      <FontAwesomeIcon v-if="props.icon" :icon="['far', props.icon]" class="native-modal-confirmation--icon"
        :class="{ [`text-${props.iconStyle}`]: true }" />
      <div class="native-modal-confirmation--content" :class="{ 'with-icon': props.icon }">
        <slot />
      </div>
    </div>
    </ModalContent>
    <ModalFooter>
      <Button :icon="faXmark" @click="handleReject()" class="color-temp-white">{{ rejectText }}</Button>
      <ButtonPrimary :icon="faArrowRight" @click="handleAccept()" class="color-temp-white">{{ confirmText }}
      </ButtonPrimary>
    </ModalFooter>
  </ModalContainer>
</template>

<style scoped lang="scss">
.native-modal-confirmation {
  .native-modal-confirmation--title {
    margin: -25px -25px 25px -25px;
    padding: 25px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .native-modal-confirmation--content-wrapper {
    margin-top: 25px;
    display: flex;
    flex: 1 auto;
  }

  .native-modal-confirmation--content {
    margin-top: 0;

    &.with-icon {
      margin-left: 25px;
    }
  }

  .native-modal-confirmation--icon {
    font-size: 45px;
  }

  .native-modal-confirmation--actions {
    margin-top: 18px;
    margin-left: 208px;
    button {
      width: 110px;
      height: 31px;
      margin-left: 10px;
    }
  }

  .native-modal-confirm--action-confirm {
    margin-left: 10px;
  }
}
</style>
