<script setup lang="ts">
import { toRef } from "vue";
import { withoutKeys } from '@/helpers'
import type { ZodSchema } from 'zod'
import { useUnifiiField } from '@/composables/inputs'

// Prevent attribute inheritance.
defineOptions({ inheritAttrs: false })

const props = withDefaults(
  defineProps<
    Partial<{
      name: string
      value: string | null
      type: 'text' | 'tel' | 'email'
      rules: ZodSchema
      inline: boolean
      required: boolean
      errorVariant: 'tooltip' | 'inline'
    }>
  >(),
  { name: '', value: '', type: 'text', errorVariant: 'inline' }
)

const $emit = defineEmits<{ 'update:value': [string] }>()

const {
  errorMessage,
  value: currentValue,
  handleBlur,
  handleChange,
  handleInput,
  resetField
} = useUnifiiField({
  name: toRef(props, 'name'),
  rules: toRef(props, 'rules'),
  initialValue: props.value,
  emitter: (value: string) => $emit('update:value', value)
})

defineExpose({
  reset: () => {
    // Reset the field.
    resetField()

    // Emit a change with the reset value.
    $emit('update:value', currentValue.value)
  }
})
</script>

<template>
  <div :class="{ 'd-inline-block': inline, 'd-block': !inline }">
    <div
      class="position-relative flex-nowrap"
      :class="[
        {
          'has-validation': errorMessage,
          'input-group': 'prefix' in $slots || 'suffix' in $slots,
          [$style['required']]: required
        },
        $style['input-text'],
        $attrs.class
      ]">
      <span class="input-group-text" v-if="'prefix' in $slots"><slot name="prefix" /></span>
      <input
        v-bind="withoutKeys($attrs, ['class'])"
        v-tooltip="errorVariant === 'tooltip' ? { text: errorMessage, variant: 'error' } : undefined"
        :type="type"
        :name="name"
        :value="currentValue"
        class="form-control"
        :class="{ 'is-invalid': errorMessage }"
        @change="handleChange"
        @blur="handleBlur"
        @input="handleInput" />
      <div class="invalid-tooltip" v-if="errorVariant === 'inline' && errorMessage">{{ errorMessage }}</div>
      <span class="input-group-text" v-if="'suffix' in $slots"><slot name="suffix" /></span>
    </div>
  </div>
</template>

<style module lang="scss">
@use '@/assets/styles/mixins-inputs';
@use "@/assets/mixins/inputs";

@import '@/assets/variables';
@import '@/assets/bootstrap-variables';

.input-text {
  input {
    appearance: textfield;
  }

  @include mixins-inputs.fix-error-tooltips-for-inputs;

  // Apply styling for prefixes/suffixes.
  @include inputs.apply-prefix-and-suffix-styles;
}

// Apply the required indicator styles.
.required {
  @include inputs.apply-required-indicator-styles;
}
</style>
