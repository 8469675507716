export const enum AccessArea {
  Administrator = 'Administrator',
  Project = 'ProjectsModule',
  Product = 'ProductSponsor',
  AssetManagement = 'AssetManagement',
  BulkPayment = 'BulkPayment',
  SystemAdministrator = 'UnifiiAdmin'
}

export const enum AccessRight {
  Executive = 'Executive',
  ViewOnly = 'ViewOnly',
  Basic = 'Basic',
  ViewAll = 'ViewAll'
}
