import { createRouter, createWebHistory } from 'vue-router'

import requireAuthGuard from "@/router/guards/require-auth"
import setPageTitleGuard from "@/router/guards/set-page-title"
import routes from './routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  strict: false,
  routes: routes.map(
    route => {
      if (!('props' in route)) {
        route.props = true
      }

      if (route.meta && !('requiresAuth' in route.meta)) {
        route.meta.requiresAuth = true
      }

      return route
    }
  )
})

// Add guards.
router.beforeEach(requireAuthGuard)
router.afterEach(setPageTitleGuard)

export default router;

