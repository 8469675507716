<script setup lang="ts">
import { ref, toRef } from "vue";
import { withoutKeys } from '@/helpers'
import type { ZodSchema } from 'zod'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useUnifiiField } from '@/composables/inputs'

// Prevent attribute inheritance.
defineOptions({ inheritAttrs: false })

const props = withDefaults(
  defineProps<
    Partial<{
      name: string
      value: string|null
      rules: ZodSchema
      required: boolean
      inline: boolean
      errorVariant?: 'inline' | 'tooltip'
    }>
  >(),
  { name: '', value: '', errorVariant: 'inline' }
)

const $emit = defineEmits<{ 'update:value': [string] }>()

const showPassword = ref(false)

const {
  errorMessage,
  handleBlur,
  handleChange,
  handleInput,
  value: currentValue
} = useUnifiiField({
  name: toRef(props, 'name'),
  rules: toRef(props, 'rules'),
  initialValue: props.value,
  emitter: (value: string) => $emit('update:value', value),
})
</script>

<template>
  <div :class="{ 'd-inline-block': inline, 'd-block': !inline }">
    <div
      class="input-group"
      :class="[
        {
          'has-validation': errorMessage,
          [$style['required']]: required
        },
        $style['input-password'],
        $attrs.class
      ]">
      <input
        v-bind="withoutKeys($attrs, ['class'])"
        v-tooltip="errorVariant === 'tooltip' ? { text: errorMessage, variant: 'error' } : undefined"
        :type="showPassword ? 'text' : 'password'"
        :name="name"
        :value="currentValue"
        class="form-control"
        :class="{ 'is-invalid': errorMessage }"
        @change="handleChange"
        @blur="handleBlur"
        @input="handleInput" />
      <div class="invalid-tooltip" v-if="errorVariant === 'inline' && errorMessage">{{ errorMessage }}</div>

      <span class="input-group-text" @click="showPassword = !showPassword">
        <FontAwesomeIcon :icon="showPassword ? faEyeSlash : faEye" style="width: 15px" />
      </span>
    </div>
  </div>
</template>

<style module lang="scss">
@use '@/assets/styles/mixins-inputs';
@use '@/assets/mixins/inputs';

@import '@/assets/variables';

.input-password {
  input {
    // Hide password reveal.
    &::-ms-reveal {
      display: none;
    }
  }

  @include mixins-inputs.fix-error-tooltips-for-inputs;
  @include inputs.apply-prefix-and-suffix-styles;
}

.required {
  @include inputs.apply-required-indicator-styles;
}
</style>
