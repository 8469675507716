import type { SortedField } from '@/types'
import { z, type ZodTypeAny } from "zod";
import { computed, type MaybeRefOrGetter, toValue } from "vue";
import { type RouteLocationNormalizedLoaded, type Router, useRoute, useRouter } from 'vue-router'
import { assertSchema } from '@/helpers/assert-schema'
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string'
import { createLogger } from "@/helpers/logging";

export type PaginationParameters = Partial<{
  sorting: MaybeRefOrGetter<SortedField[]>
  page: MaybeRefOrGetter<number | undefined>
  pageSize: MaybeRefOrGetter<number | undefined>
}>

export function useQueryParameter<
  TSchema extends ZodTypeAny,
  TFallback extends z.infer<TSchema> | undefined = undefined
>(
  name: string,
  schema: TSchema,
  fallback?: MaybeRefOrGetter<TFallback>
) {
  const router = useRouter()
  const route = useRoute()
  const logger = createLogger(`composables:useQueryParameter:${name}`)

  return computed({
    get: () => {
      const value = getQueryValue(route, name)

      if (value === undefined) {
        logger.debug(`[${name}] is undefined. Defaulting to fallback value.`)

        return toValue(fallback)
      }

      try {
        const parsed = unmangle(value, toValue(fallback))
        const result = schema.safeParse(parsed)

        if (result.success) {
          return result.data
        }

        logger.debug(
          `Can't marshal the raw value to the correct type (reason: ${result.error.message}). Defaulting to fallback value.`,
          { error: result.error }
        )

        return toValue(fallback)
      } catch (e: unknown) {
        logger.debug(
          `Can't marshal the raw value to the correct type (reason: ${e}). Defaulting to fallback value.`,
          { error: e }
        )

        return toValue(fallback)
      }
    },
    set: (newValue) => {
      // Unsetting the value.
      if (newValue === undefined) {
        logger.debug(`Unsetting query parameter.`)

        updateQuery(router, route, { [name]: undefined })
        return
      }

      try {
        assertSchema(newValue, schema)

        updateQuery(router, route, { [name]: mangle(newValue) })
      } catch (e: unknown) {
        logger.debug(`Validation failed; clearing route query.`, { error: e })

        updateQuery(router, route, { [name]: undefined })

        return
      }
    }
  })
}

export function usePageQueryParameter(fallback: MaybeRefOrGetter<number> = 1, key: string = 'page') {
  return useQueryParameter(key, z.number().int().gte(1), fallback)
}

export function usePageSizeQueryParameter(fallback: MaybeRefOrGetter<number> = 10, key: string = 'limit') {
  return useQueryParameter(key, z.number().int().gte(1).lte(250), fallback)
}

export function useSortingQueryParameter(fallback: MaybeRefOrGetter<SortedField[]> = [], key: string = 'sort') {
  const sorting = useQueryParameter(key, z.string().min(1))

  return computed({
    get() {
      const trimmed = sorting.value?.trim() || ''

      if (trimmed.length < 1) {
        return toValue(fallback) || []
      }

      return trimmed.split(',')
        .map(
          (field: string): SortedField|undefined => {
            const trimmed = field.trim()

            if (trimmed.length < 1) {
              return undefined
            }

            const direction = trimmed[0] === '-' ? 'desc' : 'asc'
            field = field.replace(/^[+-]+/, '')

            if (field.length < 1) {
              return undefined
            }

            return { field, direction }
          }
        )
        .filter(
          (x: SortedField|undefined): x is SortedField => x !== undefined
        )
    },
    set(value: SortedField[]) {
      sorting.value = value.length > 0
        ? value.map(x => `${x.direction === 'desc' ? '-' : ''}${x.field}`).join(',')
        : undefined
    }
  })
}

function mangle(value: any) {
  try {
    return compressToEncodedURIComponent(JSON.stringify(value))
  } catch (e: unknown) {
    return undefined
  }
}

function unmangle<T>(value: string, fallback: T) {
  try {
    return JSON.parse(decompressFromEncodedURIComponent(value)) as T
  } catch (e: unknown) {
    return fallback
  }
}

function updateQuery(router: Router, route: RouteLocationNormalizedLoaded, params: Record<string, MaybeRefOrGetter>) {
  return router.replace({
    query: {
      ...route.query,
      ...Object.fromEntries(Object.entries(params).map(([k, v]) => [k, toValue(v)]))
    }
  })
}

function getQueryValue(
  route: RouteLocationNormalizedLoaded,
  name: string
) {
  if (!(name in route.query)) {
    return undefined
  }

  const possibleArrayValue = route.query[name]
  const normalizedValue = Array.isArray(possibleArrayValue) ? possibleArrayValue[0] : possibleArrayValue

  if (normalizedValue === null || normalizedValue === undefined) {
    return undefined
  }

  return normalizedValue
}
