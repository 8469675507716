import { computed, type MaybeRefOrGetter, toValue } from "vue";
import { useQuery } from '@tanstack/vue-query'
import { getUserDetails } from '@/features/users/users'

export default function useUserDetails(id: MaybeRefOrGetter<string | undefined>) {
  const staleTime = computed(
    () => toValue(id) === 'me' ? 60_000 : undefined
  )

  return useQuery({
    queryKey: ['users:details', id],
    staleTime,
    enabled: () => toValue(id) !== undefined,
    queryFn: () => getUserDetails(toValue(id)!)
  })
}
