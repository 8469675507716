<script setup lang="ts">

</script>

<template>
  <div :class="$style['content-navigation-tab']">
    <slot />
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.content-navigation-tab {
  display: flex;
  gap: 30px;
  margin-block: 27px 34px;

  a {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: $body-color;
    cursor: pointer;

    &:global(.router-link-active) {
      color: $teal;
      border-bottom: 2px solid $teal;
      cursor: pointer;
      opacity: 1;
    }

    &[aria-disabled="true"] {
      opacity: 0.5;
    }
  }
}


</style>
