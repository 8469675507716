import makeApiClient, { Type } from '@/features/api'

type Argument = {
  username: string
  password: string
}

const { sendPostRequest } = makeApiClient(Type.Core)

export default async function authenticate({ username, password }: Argument) {
  await sendPostRequest('authentication/tokens', {
    username,
    password,
    useCookie: true
  })
}