<script setup lang="ts">
import LogoAnimated from "@/components/logo-animated.vue";
import useLoader from "@/composables/loader";

withDefaults(
  defineProps<{ text?: string }>(),
  { text: 'Loading...' }
)

const { isActive } = useLoader()
</script>

<template>
  <Transition>
    <div v-if="isActive" :class="$style.loader" class="d-flex justify-content-center align-items-center">
      <div>
        <LogoAnimated :size="300" />
        <div :class="$style['text']" v-if="text">{{ text }}</div>
      </div>
    </div>
  </Transition>
</template>

<style module lang="scss">
@import "@/assets/variables";

.loader {
  overflow: hidden;
  background: none;
  border: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 0;
  left: 0;
  color: $body-color;
  background: rgba(255, 255, 255, 0.4);

  &:global {
    &.v-enter-from, &.v-leave-to {
      opacity: 0;
    }

    &.v-enter-to, &.v-leave-from {
      opacity: 1;
    }

    &.v-enter-active, &.v-leave-active {
      transition: all 150ms ease;
    }
  }
}

.text {
  margin-top: 30px;
  text-transform: uppercase;
  color: $body-color;
  text-align: center;
  font-size: 25px;
  letter-spacing: 3px;
}
</style>