<script setup lang="ts">
import {
  faChevronDown,
  faHandHoldingBox,
  faInventory,
  faListUl,
  faTasks
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed } from 'vue'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { type RouteLocationNamedRaw, RouterLink, useRoute } from "vue-router";
import { makeCoreUrl } from '@/helpers/routing'
import useAuthenticatedUserDetails, { AccessArea } from "@/composables/users/authenticated-user-details";
import routeNames from '@/router/names'

const route = useRoute()
const { hasPermission, data: authenticatedUser } = useAuthenticatedUserDetails()

const availableNavs: {
  icon: IconDefinition
  text: string
  children: { text: string; href?: string; route?: RouteLocationNamedRaw, active?: () => boolean }[]
  href?: string
  route?: RouteLocationNamedRaw,
  visible: () => boolean
}[] = [
  {
    icon: faListUl,
    text: 'My Projects',
    route: { name: routeNames.projectsDashboard },
    visible: () => true,
    children: [
      {
        text: 'Projects',
        route: { name: routeNames.projectsDashboard },
        active: () => route.name === routeNames.projectsDashboard
      }
    ]
  },
  {
    icon: faInventory,
    text: 'Manage Deals',
    href: makeCoreUrl('Deals/DealTask'),
    visible: () => !!(authenticatedUser.value?.flags.isTechnicalReviewer || authenticatedUser.value?.flags.isValidator),
    children: []
  },
  {
    icon: faHandHoldingBox,
    text: 'Project Delivery',
    href: makeCoreUrl('ProjectLeadDelivery'),
    visible: () => hasPermission(AccessArea.Administrator),
    children: [{
      text: 'Project Delivery',
      href: makeCoreUrl('ProjectLeadDelivery'),
    }]
  },
  {
    icon: faTasks,
    text: 'Asset Management',
    href: makeCoreUrl('Operations/AssetManagementDashboard'),
    visible: () => hasPermission(AccessArea.AssetManagement),
    children: [
      {
        text: 'Assets',
        href: makeCoreUrl('Operations/AssetManagementDashboard'),
        active: () => route.name?.toString().startsWith('assetManagement.assets.') || false,
      },
      {
        text: 'Tasks',
        href: makeCoreUrl('Operations/GetAssetTaskManagementDashboard'),
        active: () => route.name?.toString().startsWith('assetManagement.tasks.') || false,
      },
      {
        text: 'Invoicing',
        route: { name: routeNames.assetInvoicesDashboard },
        active: () => route.name?.toString().startsWith('assetManagement.invoicing.') || false,
      }
    ]
  }
]

const activeNav = computed(
  () => {
    const index = (route.name?.toString() || '').startsWith('assetManagement.') ? 3 : 0

    return { index, nav: availableNavs[index] }
  }
)

</script>

<template>
  <nav class="navbar navbar-expand pt-0 pb-0" :class="$style['nav-secondary']">
    <div class="container-fluid justify-content-start">
      <div class="dropdown">
        <a href="#" class="ps-4 pe-4" data-bs-toggle="dropdown">
          <FontAwesomeIcon :icon="activeNav.nav.icon" class="me-1" />
          {{ activeNav.nav.text }}
          <FontAwesomeIcon :icon="faChevronDown" class="ps-4" />
        </a>
        <ul class="dropdown-menu">
          <template v-for="(item, index) in availableNavs" :key="`nav:${index}`">
            <li v-if="item.visible()">
              <a v-if="item.href" :href="item.href" class="dropdown-item">
                <FontAwesomeIcon :icon="item.icon" class="me-1" />
                {{ item.text }}
              </a>
              <RouterLink v-if="item.route" :to="item.route" class="dropdown-item">
                <FontAwesomeIcon :icon="item.icon" class="me-1" />
                {{ item.text }}
              </RouterLink>
            </li>
          </template>
        </ul>
      </div>
      <ul v-if="activeNav.nav.children.length > 0" class="navbar-nav ms-4 ps-4">
        <li v-for="(item, index) in activeNav.nav.children" :key="`nav:${activeNav.index}:child:${index}`">
          <a v-if="item.href" :href="item.href" class="nav-link" :class="{ active: item.active?.() }">
            {{ item.text }}
          </a>
          <RouterLink v-if="item.route" :to="item.route" class="nav-link" :class="{ active: item.active?.() }">
            {{ item.text }}
          </RouterLink>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style module lang="scss">
.nav-secondary {
  background: transparent linear-gradient(90deg, #28a09c 0%, #fee351 100%) 0 0 no-repeat padding-box;

  :global {
    .dropdown [data-bs-toggle="dropdown"] {
      padding: 0.85rem 1.5rem;
      display: block;
    }

    .dropdown > a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      padding: 0.85rem 1.5rem;
    }

    .navbar-nav {
      border-left: 1px solid rgba(255, 255, 255, 0.25);
    }

    .nav-link.nav-link {
      text-transform: uppercase;
      color: #fff;
      padding: 0.85rem 1.5rem;
      text-shadow: 0 0 5px #0e3a47;
      transition: none;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &.active {
        color: #6d6e71;
        font-weight: bold;
        text-shadow: none;
        background: #fff;
      }
    }
  }
}
</style>
