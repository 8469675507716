<script setup lang="ts">
import { ref, computed } from "vue";
import Button from "@/components/button-secondary.vue";
import { faPlus, faFloppyDisk } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import InputSelect from "@/components/input-select.vue";
import InputText from "@/components/input-text.vue";
import InputLabel from "@/components/input-label.vue";
import { type Contact, ContactsSchema } from "@/features/contacts/schemas";
import { useAssetSettingsLookups } from '@/composables/assets/assets';
import { useContactTypes, useAddContact } from '@/composables/contacts/contacts';
import GridContainer from "@/components/grid-container.vue";
import GridItem from "@/components/grid-item.vue";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import useNotifications from "@/composables/notifications";
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import ModalFooterConfirmation from '@/components/modal/modal-footer-confirmation.vue'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { z } from 'zod'

const props = defineProps<{
    assetCode: number;
}>();
const emit = defineEmits<{
    (e: "closed"): void;
}>();
const { data: lookups, isLoading: lookupsLoading, error } = useAssetSettingsLookups();
const { data: contactTypes, isLoading: contactTypesLoading, error: contactTypesError } = useContactTypes();
const { mutateAsync, isPending } = useAddContact(props.assetCode)
const { addSuccess, addError } = useNotifications();

const isLoading = computed(() => lookupsLoading.value || contactTypesLoading.value || isPending.value)

const { handleSubmit } = useForm({
  validationSchema: toTypedSchema(
    ContactsSchema.merge(
      z.object({
        firstname: z.string().min(1),
        countryId: z.number().int().gt(0),
        emailAddresses: z.array(z.object({ emailAddress: z.string().email() })).min(1),
      })
    )
  )
})

const data = ref<Contact>(ContactsSchema.parse({}));
const addPhoneNumber = () => data.value.phoneNumbers.push({ id: 0, contactId: data.value.id, phoneNumber: '' });
const addEmail = () => data.value.emailAddresses.push({ id: 0, contactId: data.value.id, emailAddress: '' });

const save = handleSubmit(
  async (contact) => {
    try {
      await mutateAsync({
        ...contact,
        emailAddresses: contact.emailAddresses.map(v => ({ ...v, contactId: 0, id: 0 })),
        phoneNumbers: contact.phoneNumbers.map(v => ({ ...v, contactId: 0, id: 0 })),
      });

      addSuccess({ title: 'Contact saved successfully.' })

      emit('closed')
    } catch (e: unknown) {
      addError({
        title: 'Unable to save contact.',
        message: `Something went wrong trying to save this contact. Please try again.`
      })
    }
  }
)

const handleReject = async () => {
    emit("closed");
};
</script>
<template>
    <ModalContainer class="native-modal-confirmation">
      <ModalTitle>Contact</ModalTitle>
        <ModalContent class="native-modal-confirmation--content-wrapper">
            <div class="native-modal-confirmation--content">
                <ContentLoader :loading="isLoading" variant="overlay">
                    <HeroSplashError v-if="error || contactTypesError" />
                    <GridContainer :columns="[122, '1fr']" :align="'center'">
                        <GridItem>
                            <InputLabel class="card-input-title">
                                Name
                            </InputLabel>
                        </GridItem>
                        <GridItem>
                            <InputText type="text" placeholder="Type name here" name="firstname" error-variant="tooltip" />
                        </GridItem>
                        <GridItem>
                            <InputLabel class="card-input-title">
                                Surname
                            </InputLabel>
                        </GridItem>
                        <GridItem>
                            <InputText type="text" placeholder="Type surname here" name="surname" />
                        </GridItem>
                        <GridItem :span="2" v-for="(num, index) in data.phoneNumbers" :key="index">
                            <GridContainer :columns="[122, '1fr']" :align="'center'">
                                <GridItem :span="1">
                                    <InputLabel class="card-input-title">
                                        Contact No.
                                    </InputLabel>
                                </GridItem>
                                <GridItem :span="1" v-if="index == 0">
                                    <GridContainer :columns="[262, '1fr']">
                                        <GridItem>
                                            <InputText class="contact-modal-input" type="text" error-variant="tooltip"
                                                placeholder="888 888 8888" :name="`phoneNumbers[${index}].phoneNumber`" />
                                        </GridItem>
                                        <GridItem>
                                            <Button class="add-contact-button" @click="addPhoneNumber()">
                                                <FontAwesomeIcon :icon="faPlus" />
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem :span="1" v-else>
                                    <InputText class="contact-modal-input" type="text" placeholder="888 888 8888" error-variant="tooltip"
                                               :name="`phoneNumbers[${index}].phoneNumber`" />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem :span="2" v-for="(email, index) in data.emailAddresses" :key="index">
                            <GridContainer :columns="[122, '1fr']" :align="'center'">
                                <GridItem :span="1">
                                    <InputLabel class="card-input-title">
                                        Email Address
                                    </InputLabel>
                                </GridItem>
                                <GridItem :span="1" v-if="index == 0">
                                    <GridContainer :columns="[262, '1fr']">
                                        <GridItem>
                                            <InputText class="contact-modal-input" type="text" error-variant="tooltip"
                                                placeholder="email@domain.com" :name="`emailAddresses[${index}].emailAddress`" />
                                        </GridItem>
                                        <GridItem>
                                            <Button class="add-contact-button" @click="addEmail()">
                                                <FontAwesomeIcon :icon="faPlus" />
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem :span="1" v-else>
                                    <InputText class="contact-modal-input" type="text" placeholder="email@domain.com" error-variant="tooltip"
                                        :name="`emailAddresses[${index}].emailAddress`" />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem>
                            <InputLabel class="card-input-title">
                                Contact Type
                            </InputLabel>
                        </GridItem>
                        <GridItem>
                            <InputSelect type="text" placeholder="Please select" align-help="left" item-text="name" name="contactTypeId"
                                item-value="id" :items="contactTypes || []"  disabled/>
                        </GridItem>
                        <GridItem>
                            <InputLabel class="card-input-title">
                                Street
                            </InputLabel>
                        </GridItem>
                        <GridItem>
                            <InputText type="text" placeholder="Type street here" name="street" />
                        </GridItem>
                        <GridItem>
                            <InputLabel class="card-input-title">
                                Suburb
                            </InputLabel>
                        </GridItem>
                        <GridItem>
                            <InputText type="text" placeholder="Type suburb here" name="suburb" />
                        </GridItem>
                        <GridItem>
                            <InputLabel class="card-input-title">
                                City
                            </InputLabel>
                        </GridItem>
                        <GridItem>
                            <InputText type="text" placeholder="Type city here" name="city" />
                        </GridItem>
                        <GridItem>
                            <InputLabel class="card-input-title">
                                Country
                            </InputLabel>
                        </GridItem>
                        <GridItem>
                            <InputSelect type="text" placeholder="Please select" align-help="left" item-text="name" error-variant="tooltip"
                                item-value="id" :items="lookups?.countries || []" name="countryId" />
                        </GridItem>
                    </GridContainer>
                </ContentLoader>
            </div>
        </ModalContent>
      <ModalFooterConfirmation
        confirm-text="Save"
        :confirm-icon="faFloppyDisk"
        reject-text="Cancel"
        @reject="handleReject"
        @confirm="save"
      />
    </ModalContainer>

</template>
<style scoped lang="scss">
.native-modal-confirmation {
    min-width: 500px;
    min-height: 550px;
    overflow-x: hidden;

    .native-modal-confirmation--title {
        margin: -25px -25px 25px -25px;
        padding: 25px;
        border-bottom: 1px solid #ddd;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .native-modal-confirmation--content {
        margin-top: 0;

        &.with-icon {
            margin-left: 25px;
        }
    }

    .native-modal-confirmation--icon {
        font-size: 45px;
    }

    .native-modal-confirmation--actions {
        width: 100%;
        margin-top: 18px;
        margin-left: 210px;
        color: #fff;

        button {
            width: 110px;
            height: 31px;
            margin-left: 10px;
        }
    }

    .native-modal-confirm--action-confirm {
        margin-left: 10px;
    }
}
</style>
