import makeApiClient, { Type } from '@/features/api'
import type { Projects } from "@/types";

type Argument = {
  projectId: number
  type: Projects.TransferType
}

const { sendPutRequest } = makeApiClient(Type.Core)

export default async function acceptProjectTransfer({ projectId, type }: Argument) {
  await sendPutRequest(`projects/${projectId}/transfers/${type}`)
}