<script setup lang="ts" generic="TSelection extends any, TRecord extends any">
import {
  provide,
  ref,
  computed,
  watch,
  type HTMLAttributes
} from "vue";
import { useVModel } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    records: TRecord[]

    sorting?: SortedField[]
    selection?: TSelection[]
    selectionValue?: (record: TRecord, index: number) => TSelection
    rowAttrs?: (record: TRecord, index: number) => HTMLAttributes
  }>(),
  {
    sorting: () => [],
    rowAttrs: () => ({ })
  }
)

const emit = defineEmits<{
  'update:sorting': [SortedField[]]
  'update:selection': [TSelection[]]
}>()

defineSlots<{
  headers: () => any
  record: (props: { record: TRecord, records: TRecord[], index: number }) => any
  context: (props: { record: TRecord, records: TRecord[], index: number }) => any
  'message:empty': () => any
}>()

const $selectAll = ref<HTMLInputElement>()
const $thead = ref<HTMLTableCellElement>()
const sorting = useVModel(props, 'sorting', emit)

const selectedValues = ref(
  new Map<TSelection, boolean>(
    props.selection?.map(x => [x, true])
  )
)

const isSelectable = computed(
  () => props.selection !== undefined && typeof props.selectionValue === 'function'
)

const sortingMap = computed(
  () => sorting.value.reduce<{
    [k: string]: { direction: SortedField['direction'], index: number }
  }>(
    (prev, current, index) => {
      prev[current.field] = { direction: current.direction, index }

      return prev
    },
    {}
  )
)

const columnCount = computed(
  () => $thead.value?.querySelectorAll('th').length
)

const valuesForSelection = computed<TSelection[]>(
  () => {
    if (!isSelectable.value) {
      return []
    }

    const selector = props.selectionValue

    if (typeof selector !== 'function') {
      return []
    }

    return props.records.map((value, index) => selector(value, index))
  }
)

// Emit events when the selection changes.
watch(
  selectedValues,
  x => emit('update:selection', Array.from(x.keys())),
  { deep: true }
)

watch(
  [selectedValues, valuesForSelection],
  ([selected, availableSelection]) => {
    const $el = $selectAll.value

    if (!$el) {
      return
    }

    // Has all values in the table selected.
    if (availableSelection.every(x => selected.get(x) === true)) {
      $el.checked = true
      $el.indeterminate = false
    }
    // Has some values in the table selected.
    else if (availableSelection.some(x => selected.get(x) === true)) {
      $el.indeterminate = true
      $el.checked = true
    }
    // Has no values in the table selected.
    else {
      $el.indeterminate = false
      $el.checked = false
    }
  },
  { immediate: true, deep: true }
)

// Provide functionality to child components.
provide(SortingInjectionKey, { sortingMap, toggleSorting })

function changeDirection(direction?: 'asc' | 'desc') {
  if (direction === undefined) {
    return 'asc'
  }

  return direction === 'asc' ? 'desc' : 'asc'
}

function toggleSorting(field: string, append: boolean) {
  // If currently sorting by multiple || !sorting multiple && field is different, then set it straight to asc.
  // If we're replacing the sorting, then just set it straight.
  // If we're appending, then it's easy.

  if (append) {
    if (field in sortingMap.value) {
      const existing = sortingMap.value[field]

      sorting.value[existing.index].direction = changeDirection(existing.direction)
      sorting.value = [...sorting.value]
    } else {
      sorting.value = [...sorting.value, { field, direction: 'asc' }]
    }

    return
  }

  const currentlyIsMultiple = sorting.value.length > 1

  // Not sorting by multiple fields and are sorting by current field, then
  // change direction.
  if (!currentlyIsMultiple && field in sortingMap.value) {
    sorting.value = [{ field, direction: changeDirection(sortingMap.value[field].direction) }]
  }
  // Either changing sorted field, or changing from multiple -> single sort.
  else {
    sorting.value = [{ field, direction: 'asc' }]
  }
}

function toggleSelection(e: Event, index: number, record: TRecord) {
  if (!isSelectable.value) {
    return
  }

  const value = valuesForSelection.value[index]
  const isCurrentlySelected = selectedValues.value.get(value) === true

  if (isCurrentlySelected) {
    selectedValues.value.delete(value)
  } else {
    selectedValues.value.set(value, true)
  }

  // Make sure the checkbox reflects what is happening in the actual selection.
  if (e.target instanceof HTMLInputElement) {
    e.target.checked = !isCurrentlySelected
  }
}

function toggleAll(event: MouseEvent) {
  const target = event.target as HTMLInputElement

  if (target.checked) {
    for (const value of valuesForSelection.value) {
      selectedValues.value.set(value, true)
    }
  } else {
    for (const value of valuesForSelection.value) {
      selectedValues.value.delete(value)
    }
  }
}

function isSelected(index: number, record: TRecord) {
  if (typeof props.selectionValue !== 'function') {
    return false
  }

  return selectedValues.value.get(props.selectionValue(record, index)) === true
}
</script>

<script lang="ts">
import type { ComputedRef, InjectionKey } from "vue";
import type { SortedField } from '@/types'

export const SortingInjectionKey: InjectionKey<{
  sortingMap: ComputedRef<{ [k: string]: { index: number, direction: SortedField['direction'] }|undefined }>,
  toggleSorting: (field: string, append: boolean) => void
}> = Symbol()
</script>

<template>
  <table class="table w-100 table-hover u-data-table">
    <thead ref="$thead">
      <tr>
        <th v-if="isSelectable">
          <input type="checkbox" class="form-check-input" ref="$selectAll" @click="toggleAll" v-tooltip="selectedValues.size > 0 ? `${selectedValues.size} selected` : undefined" />
        </th>

        <slot name="headers" />

        <th v-if="'context' in $slots" class="u-header-context"></th>
      </tr>
    </thead>
    <tbody>
      <template v-if="records.length < 1">
        <td :colspan="columnCount" class="text-center pt-2 pb-2">
          <slot name="message:empty">
            No matching records found
          </slot>
        </td>
      </template>
      <template v-else>
        <tr v-for="(record, index) in records" :key="index" v-bind="rowAttrs(record, index)">
          <td v-if="isSelectable">
            <input
              type="checkbox"
              class="form-check-input"
              :checked="isSelected(index, record)"
              @change="toggleSelection($event, index, record)" />
          </td>

          <slot name="record" :record="record" :records="records" :index="index" />

          <td v-if="'context' in $slots">
            <slot name="context" :record="record" :records="records" :index="index" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>