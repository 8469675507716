<script setup lang="ts">
import PageTitle from '@/components/page-title.vue'
import PageNavigation from '@/components/page-navigation.vue'
import routeNames from '@/router/names'
import PageContent from '@/components/page-content.vue'
import { useAssetDetails } from '@/composables/assets/assets'
import { computed, ref } from 'vue'
import { DeliveryStatus } from '@/features/assets/constants'
import AppLink from '@/components/app-link.vue'
import { useLinkedMeters } from '@/composables/assets/meters'
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import { faCheck, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import ModalFooter from '@/components/modal/modal-footer.vue'
import ButtonPrimary from '@/components/button-primary.vue'

const props = defineProps<{ assetCode: number }>()

const { data: assetDetails } = useAssetDetails(() => props.assetCode)
const { data: linkedMeters } = useLinkedMeters(() => props.assetCode)

const isOperational = computed(
  () => assetDetails.value?.deliveryStatus === DeliveryStatus.Operational
)

const hasLinkedMeters = computed(
  () => (linkedMeters.value?.length ?? 0) > 0
)

const showPopup = ref(false)

function showPopupIf(value: boolean) {
  console.log('showPopupIf()')
  if (value) {
    showPopup.value = true
  }
}
</script>

<template>
  <PageContent>
    <PageTitle v-if="assetDetails">
      {{ assetDetails?.projectName }}

      <template #subtitle>(Project: {{ assetDetails.projectCode }} | Asset: {{ assetDetails.code }})</template>
    </PageTitle>

    <PageNavigation>
      <AppLink :to="{ name: routeNames.assetMetersDashboard, params: { assetCode } }">Link Meters</AppLink>
      <AppLink :to="{ name: routeNames.assetSettings, params: { assetCode } }" :disabled="!hasLinkedMeters" @click="showPopupIf(!hasLinkedMeters)">Settings</AppLink>
      <AppLink :to="{ name: routeNames.invoiceSetup, params: { assetCode } }" :disabled="!isOperational" @click="showPopupIf(!isOperational)">Manage Invoice</AppLink>
    </PageNavigation>

    <ModalContainer v-if="showPopup">
      <ModalTitle>Ensure all required info is provided</ModalTitle>
      <ModalContent :icon="faTriangleExclamation" icon-variant="warning">
        <p>The following should please be completed to proceed:</p>

        <p>
          <b>STEP 1:</b> Link meters associated with this asset.
          <br>
          <b>STEP 2:</b> Complete all inputs on the Settings tab.
          <br>
          <b>STEP 3:</b> Set up the Invoice Template with all recurring charges.
        </p>

        <p>
          If meters were linked, they should auto-fill the Invoice Template. Review the displayed
          information to confirm accuracy for future invoices.
        </p>
      </ModalContent>
      <ModalFooter class="text-end">
        <ButtonPrimary :icon="faCheck" @click="showPopup = false">Okay</ButtonPrimary>
      </ModalFooter>
    </ModalContainer>

    <RouterView />
  </PageContent>
</template>
