<script setup lang="ts">
import TopNav from '@/views/nav/top-nav.vue'
import { onErrorCaptured, ref } from "vue";
import VerticallyCentered from "@/components/vertically-centered.vue";
import HeroSplash from "@/components/hero-splash.vue";
import imageUrl from '@/assets/error-splash.svg'
import TheNotifications from "@/components/the-notifications.vue";
import TheLoader from "@/components/the-loader.vue";

const hasError = ref<boolean>()

onErrorCaptured(
  (error) => {
    console.error('Caught unhandled error', error, `JSON representation:\n(${JSON.stringify(error)})`)

    hasError.value = true
  }
)
</script>

<template>
  <TheNotifications />
  <TheLoader />

  <TopNav :showSecondary="!hasError" />

  <VerticallyCentered v-if="hasError">
    <HeroSplash
      title="An unexpected error has occurred."
      sub-title="(But we're on it!)"
      :image-url="imageUrl">
        <template #footer>
          Should the error persist, please contact us on <a href="mailto:support@unifii.solar">support@unifii.solar</a>.
        </template>
    </HeroSplash>
  </VerticallyCentered>

  <RouterView v-else />
</template>