import {
  buildApiUrl,
  Type,
  sendPaginationRequest,
  applyPagingToUrl,
  applyFilteringToUrl,
  applySortingToUrl
} from "@/features/api";
import { z } from "zod";
import type { SortedField } from "@/types";

const schema = z.object({
  id: z.number().int().gt(0),
  encryptedId: z.string(),
  name: z.string(),
  ownerId: z.string(),
  ownerName: z.string(),
  assigneeId: z.string().nullable(),
  assigneeName: z.string().nullable(),
  organisationId: z.number().int().gt(0),
  organisationName: z.string(),
  flags: z.object({
    isOpen: z.boolean(),
    isClosed: z.boolean(),
    userCanEdit: z.boolean(),
    userCanView: z.boolean(),
    userCanTransfer: z.boolean(),
    userCanClose: z.boolean(),
  }),
  transfers: z.array(
    z.object({
      type: z.enum(['owner', 'assignee']),
      toUserId: z.string(),
      toUserName: z.string(),
      fromUserId: z.string(),
      fromUserName: z.string(),
    })
  )
})

export type PaginatedProject = z.infer<typeof schema>

export default function getPaginatedProjectList(
  params: Partial<{
    page: number
    pageSize: number
    sort: SortedField[]
    filter: string
  }>
) {
  const url = buildApiUrl(Type.Core, 'projects')

  applyPagingToUrl(url, params.page, params.pageSize)
  applyFilteringToUrl(url, params.filter)
  applySortingToUrl(url, params.sort)

  return sendPaginationRequest(url, schema)
}
