import { buildApiUrl, sendGetRequest, Type } from "@/features/api";
import { assertSchema, assertSchemaAndReturn } from "@/helpers/assert-schema";
import { AssetSettingsSchema, AssetSettingsLookupsSchema, InvoiceCustomerSchema } from "@/features/assets/schemas";
import { z } from "zod";

export async function getAssetSettings(assetCode:number) {
  
  const url = buildApiUrl(Type.AssetManagement, `asset/${assetCode}/settings`)
  const { data } = await sendGetRequest(url)

  return assertSchemaAndReturn(data, AssetSettingsSchema)
}

export async function getAssetSettingsLookups() {
  const url = buildApiUrl(Type.AssetManagement, 'lookUp/asset/settings')
  const { data } = await sendGetRequest(url)

  return assertSchemaAndReturn(data, AssetSettingsLookupsSchema)
}

export async function getAssetCustomers(contractFundEntityId:number) {
  const url = buildApiUrl(Type.AssetManagement, `customers/contract-fund-entities/${contractFundEntityId}`)
  const { data } = await sendGetRequest(url)

  assertSchema(data, z.array(InvoiceCustomerSchema))

  return data
}
