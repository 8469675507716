import { readonly, ref, watch } from "vue";
import createDebugger from 'debug'

export type Notification = {
  type: 'warning' | 'info' | 'error' | 'success'
  title?: string
  message?: string
  autoHide: boolean
  hideDelay: number
  allowHtml: boolean
}

type NotificationArgument = Partial<Omit<Notification, 'type'>>

const debug = createDebugger('composables:useNotifications')
const notificationStack = ref<Notification[]>([])

let notificationTimeout: ReturnType<typeof setTimeout> | null = null

function shiftNotification() {
  const sizeBefore = notificationStack.value.length

  // Shifting the stack's .value directly will break the watch() callback.
  const newStack = [...notificationStack.value]
  newStack.shift()
  notificationStack.value = newStack

  debug(`Shifted notification off stack. [stackSizeBefore=${sizeBefore}] [stackSizeAfter=${notificationStack.value.length}]`)
}

watch(
  notificationStack,
  (newValue, oldValue) => {
    const hasRemovedNotification = oldValue.length - newValue.length > 0

    // If we've removed a notification, then we should always clear the timeout.
    if (hasRemovedNotification && notificationTimeout) {
      debug(`Notification was removed. Clearing notification timeout. [timeoutId=${notificationTimeout}].`)

      clearTimeout(notificationTimeout)
      notificationTimeout = null
    }

    // If we've removed a notification and there are still items in the stack,
    // or if we've added a notification, and there's only one in the stack
    // then we need to set any timeouts.
    if ((hasRemovedNotification && newValue.length > 0) || (!hasRemovedNotification && newValue.length === 1)) {
      if (newValue[0].autoHide) {
        notificationTimeout = setTimeout(shiftNotification, newValue[0].hideDelay)

        debug(`Notification timeout was set for new notification at head. [timeoutId=${notificationTimeout}] [duration=${newValue[0].hideDelay}] [stackSize=${newValue.length}]`)
      }
    }
  }
)

function addType(type: Notification['type'], notification: NotificationArgument) {
  const {
    autoHide = true,
    hideDelay = 5000,
    message,
    title,
    allowHtml = false
  } = notification

  if (title || message) {
    notificationStack.value = [...notificationStack.value, { type, title, message, hideDelay, autoHide, allowHtml }]
  }
}

export default function useNotifications() {
  return {
    notifications: readonly(notificationStack),

    clear: () => {
      notificationTimeout && clearTimeout(notificationTimeout)
      notificationTimeout = null
      notificationStack.value = []
    },

    shift: () => shiftNotification(),

    addWarning: (notification: NotificationArgument) => {
      addType('warning', notification)
    },
    addError: (notification: NotificationArgument) => {
      addType('error', notification)
    },
    addSuccess: (notification: NotificationArgument) => {
      addType('success', notification)
    },
    addInfo: (notification: NotificationArgument) => {
      addType('info', notification)
    },
  }
}